// raspberry
export const TIG_RASPBERRY_LIGHT = '#fe4d77';
export const TIG_RASPBERRY = '#f90139';
export const TIG_RASPBERRY_DARK = '#ba012b';
export const TIG_RASPBERRY_FADE = 'rgba(186, 1, 43, 0.3)';
// red
export const TIG_SOCIAL_INSTAGRAM = '#E63F5D';
export const TIG_SOCIAL_YOUTUBE = '#D40000';
export const TIG_ALERT_RED = '#bd2424';
export const TIG_RED_PISTE = '#b81516';
// gray
export const TIG_WHITE = '#FFFFFF';
export const TIG_IDEA_GRAY = '#E7E7E7';
export const TIG_TABBAR_GRAY = '#E0E0E0';
export const TIG_TOOLBAR_LIGHT_GREY = '#d1d1d1';
export const TIG_TOOLBAR_DARK_GREY = '#b9b7b7';
export const TIG_TEXTE_GRAY = '#A6A6A6';
export const TIG_GREY_TEXT_ALERT = '#848484';
export const TIG_DARK_GRAY = '#787878';
export const TIG_GREY_TEXT_DETAIL = '#5D5D5D';
export const TIG_TEXTE_DARK_GRAY = '#515151';
// green
export const TIG_GREEN = '#2AFF09';
export const TIG_GREEN_DARK = '#16a600';
export const TIG_GREEN_PISTE = '#3f9b48';
// blue
export const TIG_MAIN_COLOR_PIRATE = '#00bfa5';
export const TIG_BLUE_PISTE = '#3db0f3';
export const TIG_SOCIAL_TWITTER = '#00ABED';
export const TIG_SOCIAL_FACEBOOK = '#3B5998';
