import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import ThemeForm from "../../components/forms/ThemeForm";
import LargePaper from "../../components/common/LargePaper";

export const ThemeEditPage = props => {
  return (
    <LargePaper title="Éditer un thème">
      <ThemeForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

ThemeEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Thèmes')(ThemeEditPage);
