import React from 'react';
import {compose} from "recompose";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography/Typography";
import {getConfigurations, putConfigurations} from "../../stores/configurations/actions";
import Spinner from "../common/Spinner";
import {TIG_RASPBERRY_LIGHT} from "../../constants/colors";
import CustomForm from "../common/form/CustomForm";

const INITIAL_STATE = {
  latitude: "45.4747301",
  longitude: "6.888056",
  radius: "5000",
  tolerance: "30"
};

const styles = (theme) => ({
  margin: {
    margin: theme.spacing.unit,
  },
  cssLabel: {
    '&$cssFocused': {
      color: TIG_RASPBERRY_LIGHT
    },
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: TIG_RASPBERRY_LIGHT,
    },
  },
});

class ConfigurationForm extends React.Component {

  static propTypes = {
    token: PropTypes.string.isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    putConfigurations: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (!props.configurations && !props.pending && !props.error) {
      props.getConfigurations(props.token);
    } else {
      newState = {...state};
      if (props.configurations && state.pending) {
        newState.pending = false;
        props.configurations.forEach(function (element) {
          newState[element.key] = element.value;
        });
      }
    }
    return newState;
  }

  onSubmit = (event, formState) => {
    let sendObj = [];
    for (var property in formState) {
      sendObj.push({
        key: property,
        value: this.state[property]
      });
    }
    this.props.putConfigurations(this.props.token, sendObj);
    event.preventDefault();
  };

  render() {
    const {pending, sending} = this.props;

    const load = (<Spinner />);

    const formFields = [
      {id: 'latitude', type: 'textField', gridMD: 6, label: 'Latitude', isMandatory: true},
      {id: 'longitude', type: 'textField', gridMD: 6, label: 'Longitude', isMandatory: true},
      {id: 'radius', type: 'textField', gridMD: 6, label: 'Rayon (en mètre)', isMandatory: true},
      {id: 'tolerance', type: 'textField', gridMD: 6,
        label: 'Tolérance (en jours, laisser vide pour une tolérance infinie)', isMandatory: true},
    ];
    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : 'Sauvegarder';

    const customFrom = (
      <div>
        <Typography align="left" variant="h6" gutterBottom>
          Paramètres des notifications
        </Typography>
        <CustomForm
          data={this.state}
          formFields={formFields}
          buttonLabel={buttonLabel}
          onSubmit={this.onSubmit}
          sending={sending}
        />
      </div>
    );

    if (pending) {
      return (load);
    } else {
      return (customFrom);
    }
  }
}

function mapStateToProps(state) {
  return {
    token: state.admins.authAdmin.token,
    configurations: state.configurations.content,
    pending: state.configurations.pending,
    sending: state.configurations.sending,
    error: state.configurations.error,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getConfigurations,
    putConfigurations,
  })
)(ConfigurationForm);
