import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import Grid from "@material-ui/core/Grid";
import 'react-quill/dist/quill.snow.css';
import Typography from "@material-ui/core/Typography";

import {
  postTheme,
  putTheme,
  getThemes,
  deleteThemeBloc,
  putThemeBloc
} from "../../stores/themes/actions";
import {handleError} from "../../stores/infos/actions";
import {THEMES, THEME_BLOC_ADD, THEME_BLOC_EDIT, THEME_EDIT} from "../../constants/routes";
import Spinner from "../common/Spinner";
import StringService from "../../services/StringService";

import AuthService from "../../services/AuthService";
import {ROLE_ADMIN} from "../../constants/roles";
import CustomTable from "../common/table/CustomTable";
import CustomForm from "../common/form/CustomForm";
import stylesDefault from "../../constants/stylesDefault";

// Intial State of the Component
const INITIAL_STATE = {
  id: '',
  name: '',
  color: '#ff0000',
  isWinter: true,
  isSummerSki: false,
  isActive: true,
  themeBlocs: [],
  timeActive: StringService.timeTimestampToString(new Date() / 1000),
  dateActive: StringService.dateTimestampToString(new Date() / 1000)
};

const stateWithTheme = theme => ({
  id: theme.id,
  name: theme.name,
  color: theme.color,
  isWinter: theme.isWinter,
  isSummerSki: theme.isSummerSki,
  isActive: theme.isActive,
  themeBlocs: theme.themeBlocs,
  dateActive: StringService.dateTimestampToString(theme.date),
  timeActive: StringService.timeTimestampToString(theme.date)
});

export class ThemeForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    postTheme: PropTypes.func.isRequired,
    putTheme: PropTypes.func.isRequired,
    putThemeBloc: PropTypes.func.isRequired,
    deleteThemeBloc: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.themes && !props.pending
      && props.token && props.match && props.match.params.themeId) {
      props.getThemes(props.token);
    }
    if (props.themes && props.match && props.match.params.themeId) {
      const themes = props.themes;
      const currentThemeId = props.match.params.themeId;
      const foundTheme = themes.find(theme => theme.id === currentThemeId);
      if (state.id === INITIAL_STATE.id) {
        if (foundTheme) {
          newState = {...newState, ...stateWithTheme(foundTheme)};
        }
      }
      if (foundTheme.themeBlocs !== state.themeBlocs
        && state.themeBlocs !== INITIAL_STATE.themeBlocs) {
        newState = {...newState, themeBlocs: foundTheme.themeBlocs}
      }
    }

    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {
    // HandleDate
    formState = {
      ...formState,
      date: StringService.dateTimeStringToTimestamp(
        formState.dateActive + 'T' + formState.timeActive
      ),
      isSummerSki: !formState.isWinter && formState.isSummerSki
    };
    if (this.state.id === INITIAL_STATE.id) {
      this.props.postTheme(
        this.props.token,
        formState
      )
        .then((themeId => {
          this.props.history.push(THEME_EDIT.replace(':themeId', themeId));
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    } else {
      this.props.putTheme(
        this.props.token,
        this.state.id,
        formState
      )
        .then(() => {
          this.props.history.push(THEMES);
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
    event.preventDefault();
  };

  handleAdd = () => {
    this.props.history.push(THEME_BLOC_ADD.replace(':themeId', this.state.id));
  };

  handleEdit = element => {
    this.props.history.push(THEME_BLOC_EDIT
      .replace(':themeId', this.state.id)
      .replace(':themeBlocId', element.id));
  };

  handleDelete = element => {
    this.props.deleteThemeBloc(this.props.token, this.state.id, element)
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  handleUpOrder = element => {
    let uppedThemeBlocIndex = this.state.themeBlocs.indexOf(element);
    element.order--;
    let downedThemeBloc = this.state.themeBlocs[uppedThemeBlocIndex - 1];
    downedThemeBloc.order++;
    this.props.putThemeBloc(
      this.props.token,
      this.state.id,
      element.id,
      element
    ).catch((error) => {
      this.props.handleError(error);
    })
      .then(() => {
        this.props.putThemeBloc(
          this.props.token,
          this.state.id,
          downedThemeBloc.id,
          downedThemeBloc
        ).catch((error) => {
          this.props.handleError(error);
        });
      });
  };

  handleDownOrder = element => {
    let downedThemeBlocIndex = this.state.themeBlocs.indexOf(element);
    element.order++;
    let uppedThemeBloc = this.state.themeBlocs[downedThemeBlocIndex + 1];
    uppedThemeBloc.order--;
    this.props.putThemeBloc(
      this.props.token,
      this.state.id,
      element.id,
      element
    ).catch((error) => {
      this.props.handleError(error);
    })
      .then(() => {
        this.props.putThemeBloc(
          this.props.token,
          this.state.id,
          uppedThemeBloc.id,
          uppedThemeBloc
        ).catch((error) => {
          this.props.handleError(error);
        });
      });
  };

  render() {

    const {pending, sending} = this.props;

    const {id} = this.state;

    const columnData = [
      {id: 'order', label: 'Ordre', numeric: false, disablePadding: false},
      {
        id: 'translations[lang === \'fr\'].title', label: 'Titre',
        numeric: false, disablePadding: false
      },
      {id: 'imagePath', label: 'Image', numeric: false, image: true, disablePadding: false},
      {id: 'type', label: 'Type', numeric: false, disablePadding: false},
      {id: 'link', label: 'Lien', numeric: false, disablePadding: false},
      {
        id: 'availabilityDate', label: 'Date de disponibilité',
        dateTime: true, numeric: false, disablePadding: false
      }
    ];

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === '' ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    const formFields = [

      {id: 'block_props', type:'title', label:'Propriétés du thème', noPadding:true},
      {id: 'divider_props', type:'divider', noPadding:true},
      {id: 'name', type: 'textField', gridMD: 7, label: 'Nom', isMandatory: true},
      {id: 'color', type: 'input', gridMD: 6, label: 'Couleur', isMandatory: true},

      {
        id: 'isWinter',
        type: 'checkboxField',
        gridMD: 6,
        label: 'Mode hiver (activation BRA)',
        isMandatory: true
      },
      {
        id: 'isSummerSki',
        type: 'checkboxField',
        condition: '!isWinter',
        gridMD: 6,
        label: 'Mode SummerSki',
        isMandatory: true
      },
      {id: 'block_date', type:'title', label:'Date de publication', noPadding:true},
      {id: 'divider_date', type:'divider', noPadding:true},
      {
        id: 'dateActive',
        type: 'textField',
        subtype: 'date',
        gridMD: 6,
        label: 'Date',
        isMandatory: true
      },
      {
        id: 'timeActive',
        type:'textField',
        subtype: 'time',
        gridMD: 6,
        label: 'Heure',
        isMandatory: true
      }
    ];

    const customFrom = (
      <div style={stylesDefault.contents}>
        <CustomForm
          data={this.state}
          formFields={formFields}
          buttonLabel={buttonLabel}
          onSubmit={this.onSubmit}
          sending={sending}
        />
        {id &&
        (
          <Grid container>
            <Typography align="left" variant="h6" gutterBottom>
              Vignettes
            </Typography>
            <Grid item xs={12} sm={12} md={12}>
              {this.props.pending &&
              <Spinner />
              }
              {!this.props.pending && (
                <CustomTable
                  datas={this.state.themeBlocs}
                  onAdd={this.handleAdd}
                  onEdit={this.handleEdit}
                  onDelete={this.handleDelete}
                  buttonLabel='Ajouter une vignette'
                  paginationLabel='vignette par page'
                  emptyDataMessage='Aucune vignette'
                  deleteDialogText='Êtes-vous sûr de vouloir supprimer cette vignette ?'
                  columnData={columnData}
                  sending={this.props.sending}
                  showDelete={AuthService.isGranted(ROLE_ADMIN)}

                  canReorder
                  onUpOrder={this.handleUpOrder}
                  onDownOrder={this.handleDownOrder}
                />
              )}
            </Grid>
          </Grid>
        )}
      </div>
    );

    if (pending) {
      return (load);
    } else {
      return (customFrom);
    }
  }
}

function mapStateToProps(state) {
  return {
    token: state.admins.authAdmin.token,
    themes: state.themes.content,
    pending: state.themes.pending,
    sending: state.themes.sending
  };
}

export default compose(
  connect(mapStateToProps,
    {getThemes, postTheme, putTheme, putThemeBloc, deleteThemeBloc, handleError}),
)(ThemeForm);
