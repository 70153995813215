import React from 'react';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  TIG_IDEA_GRAY,
  TIG_RASPBERRY,
  TIG_RASPBERRY_DARK, TIG_RASPBERRY_FADE,
  TIG_RASPBERRY_LIGHT,
  TIG_TABBAR_GRAY,
  TIG_WHITE
} from "../constants/colors";
import {DEFAULT_MARGIN} from "../constants/stylesDefault";
// A theme with custom primary and secondary color.
// It'departments optional.
export const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: TIG_RASPBERRY_LIGHT,
      main: TIG_RASPBERRY,
      dark: TIG_RASPBERRY_DARK,
    },
    secondary: {
      light: TIG_WHITE,
      main: TIG_IDEA_GRAY,
      dark: TIG_TABBAR_GRAY,
    },
    action: {
      hover: TIG_IDEA_GRAY
    }
  },
  overrides: {
    MuiInput: {
      root: {
        backgroundColor: TIG_IDEA_GRAY,
        display: 'flex',
        width: '220px',
        fontWeight: 'bold',
        marginTop: DEFAULT_MARGIN,
        marginBottom: DEFAULT_MARGIN,
        padding: '4px 4px',
        flexDirection: 'row'
      },
      error: {
        color: TIG_RASPBERRY_DARK
      }
    },
    MuiButton: {
      root: {
        display: 'flex',
        width: '220px',
        fontWeight: 'bold',
        marginTop: DEFAULT_MARGIN,
        marginBottom: DEFAULT_MARGIN,
        padding: '4px 16px',
        flexDirection: 'row'
      }
    },
    MuiListItem: {
      selected: {
        backgroundColor: TIG_RASPBERRY_FADE + '!important'
      }
    },
    MuiTableSortLabel: {
      active: {
        color: TIG_WHITE
      },
      root: {
        '&:hover': {
          color: TIG_WHITE
        },
        '&:focus': {
          color: TIG_WHITE
        }
      }
    }
  }
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
