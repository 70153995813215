import {TIG_GREY_TEXT_ALERT, TIG_TEXTE_DARK_GRAY, TIG_WHITE} from "./colors";

export const notificationStyles = {
  root: {
    height: "100vh",
    width: "100vw",
    color: TIG_WHITE,
    backgroundColor: TIG_TEXTE_DARK_GRAY,
    position: 'fixe',
    top: 0,
    paddingTop: '2em'
  },
  notification: {
    backgroundColor: "rgba(255,255,255,0.8)",
    borderRadius: '4px',
    padding: '0.5em',
    margin: '0.5em'
  },
  white: {
    color: TIG_WHITE
  },
  notificationPart: {
    width: '100%',
    padding: '0.5em'

  },
  notificationHeaderPart: {
    height: '20px',
    display: 'inline-block',
    top: 0,
    position: 'relative',
    paddingRight: '0.5em'
  },
  logo: {
    height: '20px',
    width: '20px',
    top: 0,
    position: 'relative'
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
};

export const appStyles = {
  root: {
    height: "100vh",
    width: "100vw",
    position: 'fixe',
    top: 0,
    backgroundColor: TIG_WHITE
  },
  body: {
    padding: '1em'
  },
  notification: {
    padding: '0.5em',
    margin: '0.5em'
  },
  notificationPart: {
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box'
  },
  notificationHeaderPart: {
    display: 'block',
    position: 'relative',
    paddingRight: '0.5em',
    fontSize: '1em'
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%'
  },
  topPadding: {
    paddingTop: '1em',
  },
  appBar: {
    fontWeight: 800,
    fontSize: '1.75em',
    margin: 'auto',
    fontStyle: 'italic',
  },
  hour: {
    fontStyle: 'italic',
    fontWeight: 'bold',
    color: TIG_GREY_TEXT_ALERT
  }
};

export default appStyles;
