import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import WebPageBlocForm from "../../components/forms/WebPageBlocForm";
import LargePaper from "../../components/common/LargePaper";

export const WebPageBlocEditPage = props => {
  return (
    <LargePaper title="Éditer un bloc">
      <WebPageBlocForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

WebPageBlocEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Pages')(WebPageBlocEditPage);
