import React from 'react';
import { render } from 'react-dom';
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import App from './containers/App';
import './index.css';
import store from "./stores";

const rootElement = document.querySelector('#root');
if (rootElement) {
  render(
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>,
    rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
