export const ADMINS_PENDING = 'admins_pending';
export const ADMINS_FAILED = 'admins_failed';
export const ADMINS_DOWNLOADED = 'admins_downloaded';
export const ADMIN_LOGIN = 'admin_login';
export const ADMIN_REFRESH = 'admin_refresh';
export const ADMIN_REFRESH_PENDING = 'admin_refresh_pending';
export const ADMIN_LOGOUT = 'admin_logout';
export const ADMIN_UPDATE = 'admin_update';
export const ADMIN_SENDING = 'admin_sending';
export const ADMIN_DOWNLOADED = 'admin_downloaded';
export const ADMIN_REMOVED = 'admin_removed';