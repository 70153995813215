import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import ThemeBlocForm from "../../components/forms/ThemeBlocForm";
import LargePaper from "../../components/common/LargePaper";

export const ThemeBlocAddPage = props => {
  return (
    <LargePaper title="Ajouter une vignette">
      <ThemeBlocForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

ThemeBlocAddPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withAuthorization('Thèmes')(ThemeBlocAddPage);
