import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

export const alertCategoriesPendingAction = () => ({
  type: types.ALERT_CATEGORIES_PENDING,
});

export const alertCategoriesDownloadedAction = (alertCategories) => ({
  type: types.ALERT_CATEGORIES_DOWNLOADED,
  payload: alertCategories,
});

export const alertCategoriesFailedAction = (error) => ({
  type: types.ALERT_CATEGORIES_FAILED,
  payload: error,
});

export function getAlertCategories(authUserToken) {
  return (dispatch) => {
    dispatch(alertCategoriesPendingAction());
    return ApiService
      .getAlertCategoriess(authUserToken)
      .then((data) => {
        dispatch(alertCategoriesDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(alertCategoriesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}