import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import WebPageForm from "../../components/forms/WebPageForm";
import LargePaper from "../../components/common/LargePaper";

export const WebPageEditPage = props => {
  return (
    <LargePaper title="Éditer une page">
      <WebPageForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

WebPageEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Pages')(WebPageEditPage);
