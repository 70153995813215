import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import {postAdmin, putAdmin, getAdmins} from "../../stores/admins/actions";
import {handleError} from "../../stores/infos/actions";
import {ADMINS} from "../../constants/routes";

import Spinner from "../common/Spinner";
import CustomForm from "../common/form/CustomForm";

const ROLES = {
  ROLE_NOTIFIER: "notifier",
  ROLE_ADMIN: "admin",
  ROLE_SUPER_ADMIN: "super_admin"
};

// Intial State of the Component
const INITIAL_STATE = {
  id: '',
  email: '',
  lastname: '',
  firstname: '',
  password: '',
  role: ''
};

const stateWithAdmin = admin => ({
  id: admin.id,
  email: admin.email,
  lastname: admin.lastname,
  firstname: admin.firstname,
  role: admin.role
});

/**
 * Class AdminAddForm
 *
 * @Component : <AdminAddForm />
 * @Extend : React.Component
 */
export class AdminForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    postAdmin: PropTypes.func.isRequired,
    putAdmin: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authAdmin: PropTypes.object.isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (
      !props.admins
      && !props.pending
      && props.token
      && props.match
      && props.match.params.adminId
    ) {
      props.getAdmins(props.token);
    }
    if (
      props.admins
      && props.match
      && props.match.params.adminId
      && state.id === INITIAL_STATE.id
    ) {
      const admins = props.admins;
      const currentUserId = props.match.params.adminId;
      const foundUser = admins.find(admin => admin.id === currentUserId);

      if (foundUser) {
        newState = {...newState, ...stateWithAdmin(foundUser)};
      }
    }

    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {
    if (this.state.id === INITIAL_STATE.id) {
      this.props.postAdmin(
        this.props.token,
        formState
      )
        .then(() => {
          this.props.history.push(ADMINS);
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    } else {
      this.props.putAdmin(
        this.props.token,
        this.state.id,
        formState
      )
        .then(() => {
          this.props.history.push(ADMINS);
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
    event.preventDefault();
  };

  render() {

    const {pending, sending} = this.props;

    const {
      id
    } = this.state;

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === '' ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    let formFields = [
      {
        id: 'firstname',
        type: 'textField',
        gridMD: 6,
        label: 'Prénom',
        isMandatory: true
      },
      {
        id: 'lastname',
        type: 'textField',
        gridMD: 6,
        label: 'Nom',
        isMandatory: true
      },
      {
        id: 'email',
        type: 'textField',
        gridMD: 6,
        label: 'Email',
        isMandatory: true
      },
      {
        id: 'role',
        type: 'selectField',
        options: ROLES,
        gridMD: 6,
        label: 'Rôle',
        isMandatory: true
      },
    ];

    if (this.state.id === '' || this.state.id === this.props.authAdmin.id) {
      formFields.push({
        id: 'password', type: 'passwordField', errorRegex: /(?=.*\d)(?=.*[A-Z])/,
        gridMD: 6, label: 'Mot de passe', isMandatory: this.state.id === ''
      });
    }

    const customForm = (
      <CustomForm
        data={this.state}
        formFields={formFields}
        buttonLabel={buttonLabel}
        onSubmit={this.onSubmit}
        sending={sending}
      />
    );

    if (pending) {
      return (load);
    } else {
      return (customForm);
    }
  }
}

function mapStateToProps(state) {
  return {
    token: state.admins.authAdmin.token,
    authAdmin: state.admins.authAdmin,
    admins: state.admins.content,
    pending: state.admins.pending,
    sending: state.admins.sending
  };
}

export default compose(
  connect(mapStateToProps, {getAdmins, postAdmin, putAdmin, handleError}),
)(AdminForm);
