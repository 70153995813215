import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

export const alertPresetsPendingAction = () => ({
  type: types.ALERT_PRESETS_PENDING,
});

export const alertPresetsDownloadedAction = (alertPresets) => ({
  type: types.ALERT_PRESETS_DOWNLOADED,
  payload: alertPresets,
});

export const alertPresetsFailedAction = (error) => ({
  type: types.ALERT_PRESETS_FAILED,
  payload: error,
});

export function getAlertPresets(authUserToken) {
  return (dispatch) => {
    dispatch(alertPresetsPendingAction());
    return ApiService
      .getAlertPresets(authUserToken)
      .then((data) => {
        dispatch(alertPresetsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(alertPresetsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}