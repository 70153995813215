import { ENV_LOCAL, ENV_DEV } from './env';

let backendHost = 'https://tignes-api.openium.fr';
const apiVersion = 'v1';

if (process.env.REACT_APP_ENV) {
    if (process.env.REACT_APP_ENV === ENV_LOCAL) {
        backendHost = 'https://tignes-api-master.openium.fr';
    } else if (process.env.REACT_APP_ENV === ENV_DEV) {
        backendHost = 'https://tignes-api-dev.openium.fr';
    }
}

const API_ROOT = `${backendHost}/api/${apiVersion}`;
export default API_ROOT;