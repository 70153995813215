import decode from 'jwt-decode';
import {ROLES} from '../constants/roles';

export default class AuthService {

  static isGranted(neededRole) {
    let userRole = localStorage.getItem('role') || 'user';
    if (neededRole in ROLES) {
      return ROLES[neededRole].includes(userRole)
    } else {
      return false;
    }
  }

  // Check if token is expired
  static isTokenExpired() {
    try {
      const decoded = decode(localStorage.getItem('token'));
      return decoded.exp < (Date.now() / 1000);
    } catch (err) {
      return false;
    }
  }

  // Get user'departments session
  static getSession() {
    if (this.isAuthenticated()) {
      return {
        id: localStorage.getItem('id'),
        lastname: localStorage.getItem('lastname'),
        firstname: localStorage.getItem('firstname'),
        email: localStorage.getItem('email'),
        token: localStorage.getItem('token'),
        role: localStorage.getItem('role'),
        refresh_token: localStorage.getItem('refresh_token')
      }
    }

    return null;
  }

  // Save user'departments session
  static setSession(user) {
    if (user.id) {
      localStorage.setItem('id', user.id);
    }
    if (user.lastname) {
      localStorage.setItem('lastname', user.lastname);
    }
    if (user.firstname) {
      localStorage.setItem('firstname', user.firstname);
    }
    if (user.email) {
      localStorage.setItem('email', user.email);
    }
    if (user.token) {
      localStorage.setItem('token', user.token);
    }
    if (user.role) {
      localStorage.setItem('role', user.role);
    }
    if (user.refresh_token) {
      localStorage.setItem('refresh_token', user.refresh_token);
    }
  }

  // Check if user'departments session exist
  static isAuthenticated() {
    return localStorage.getItem('id') !== null
      && localStorage.getItem('email') !== null
      && localStorage.getItem('token') !== null
      && localStorage.getItem('role') !== null
      && localStorage.getItem('refresh_token') !== null
      && !this.isTokenExpired(localStorage.getItem('token'));
  }

  // Destroy user'departments session
  static logout() {
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('lastname');
    localStorage.removeItem('firstname');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
  }
}
