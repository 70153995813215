import React, {Component} from 'react';
import {compose} from "recompose";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {handleError} from "../../stores/infos/actions";
import LargePaper from "../../components/common/LargePaper";
import withAuthorization from "../../hocs/withAuthorization";
import Spinner from "../../components/common/Spinner";
import {THEME_EDIT, THEME_ADD} from "../../constants/routes";
import CustomTable from "../../components/common/table/CustomTable";
import AuthService from "../../services/AuthService";
import {ROLE_ADMIN} from "../../constants/roles";
import {deleteTheme, getThemes} from "../../stores/themes/actions";

const INITIAL_STATE = {
  datas: []
};

export class ThemesPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getThemes: PropTypes.func.isRequired,
    deleteTheme: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authAdmin: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (!props.themes && !props.pending) {
      props.getThemes(props.authAdmin.token);
    }
    if (props.themes) {
      if (state.datas === INITIAL_STATE.datas) {
        newState = {...newState, datas: props.themes};
      }
      if (props.themes !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {...newState, datas: props.themes}
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(THEME_ADD);
  };

  handleEdit = element => {
    this.props.history.push(THEME_EDIT.replace(':themeId', element.id));
  };

  handleDelete = element => {
    this.props.deleteTheme(this.props.authAdmin.token, element)
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const columnData = [
      {
        id: 'name',
        label: 'Nom',
        numeric: false,
        disablePadding: false
      },
      {
        id: 'color',
        label: 'Couleur',
        numeric: false,
        disablePadding: false
      },
      {
        id: 'date',
        label: 'Date d\'activation',
        dateTime: true,
        numeric: false,
        disablePadding: false
      },
      {
        id: 'isWinter',
        label: 'Mode hiver (activation BRA)',
        numeric: false,
        boolean: true,
        disablePadding: false
      }
    ];
    return (
      <LargePaper title="Gestion des thèmes">
        <div>
          {this.props.pending &&
          <Spinner />
          }
          {!this.props.pending && (
            <CustomTable
              datas={this.state.datas}
              onAdd={this.handleAdd}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              buttonLabel='Ajouter un thème'
              paginationLabel='thème par page'
              emptyDataMessage='Aucun thème'
              deleteDialogText='Êtes-vous sûr de vouloir supprimer ce thème ?'
              columnData={columnData}
              sending={this.props.sending}
              showDelete={AuthService.isGranted(ROLE_ADMIN)}
            />
          )}
        </div>
      </LargePaper>
    );
  }
}


function mapStateToProps(state) {
  return {
    authAdmin: state.admins.authAdmin,
    themes: state.themes.content,
    pending: state.themes.pending,
    sending: state.themes.sending,
    error: state.themes.error,
  };
}

export default compose(
  withAuthorization('Thèmes'),
  connect(mapStateToProps, {getThemes, deleteTheme, handleError})
)(ThemesPage);
