import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import ThemeForm from "../../components/forms/ThemeForm";
import LargePaper from "../../components/common/LargePaper";

export const ThemeAddPage = props => {
  return (
    <LargePaper title="Ajouter un thème">
      <ThemeForm history={props.history} />
    </LargePaper>
  );
};

ThemeAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withAuthorization('Thèmes')(ThemeAddPage);
