import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import defaultStyles from '../../constants/stylesDefault';
import {dispatchInfo, handleError} from "../../stores/infos/actions";
import apiService from '../../services/ApiService';

const INITIAL_STATE = {
  email: '',
};

export class PasswordForgetForm extends Component {
  static propTypes = {
    handleError: PropTypes.func.isRequired,
    dispatchInfo: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event) => {
    apiService.postLostPassword(this.state)
      .then(() => {
        this.props.dispatchInfo("Email envoyé");
      })
      .catch((error) => {
        this.props.handleError(error);
      });
    event.preventDefault();
  };

  render() {
    const {
      email,
    } = this.state;

    const isInvalid = email === '';

    return (
      <form onSubmit={this.onSubmit} style={defaultStyles.center}>
        <TextField
          placeholder="Email"
          id="changepassword-email"
          type="text"
          onChange={event => this.setState({email: event.target.value})}
          margin="normal"
          value={email}
          InputProps={{
            disableUnderline: true,
          }}
        />

        <Button
          variant="contained"
          color="primary"
          disabled={isInvalid}
          type="submit"
        >
          Envoyer
        </Button>
      </form>
    );
  }
}

export default connect(null, {handleError, dispatchInfo})(PasswordForgetForm);
