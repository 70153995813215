import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TIPS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.TIP_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.TIPS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.TIP_DOWNLOADED: {
      const tipDownloaded = action.payload;
      let tips = [];
      let tipIndex = -1;
      if (state.content) {
        tips = state.content.slice();
        tipIndex = tips.findIndex(tip => tip.id === tipDownloaded.id);
      }
      if (tipIndex === -1) {
        tips.push(tipDownloaded);
      } else {
        tips[tipIndex] = tipDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: tips,
      };
    }
    case types.TIP_REMOVED: {
      const tip = action.payload;
      let RemovedTipOrder = tip.order;
      let tips = state.content.slice();
      if (state.content !== null) {
        let isTip = (tipId) => {
          return function (element) {
            return element.id !== tipId;
          }
        };
        tips = state.content.filter(isTip(tip.id));
      }
      tips.forEach(function (element) {
        if (element.order > RemovedTipOrder) {
          element.order = element.order - 1;
        }
      });
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: tips,
      };
    }
    case types.TIPS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
