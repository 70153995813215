import React, {Component} from 'react';
import {compose} from "recompose";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {handleError} from "../../stores/infos/actions";
import LargePaper from "../../components/common/LargePaper";
import withAuthorization from "../../hocs/withAuthorization";
import Spinner from "../../components/common/Spinner";
import {TIP_EDIT, TIP_ADD} from "../../constants/routes";
import CustomTable from "../../components/common/table/CustomTable";
import AuthService from "../../services/AuthService";
import {ROLE_ADMIN} from "../../constants/roles";
import {deleteTip, getTips, putTip} from "../../stores/tips/actions";

const INITIAL_STATE = {
  datas: []
};

export class TipsPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getTips: PropTypes.func.isRequired,
    putTip: PropTypes.func.isRequired,
    deleteTip: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authAdmin: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (!props.tips && !props.pending) {
      props.getTips(props.authAdmin.token);
    }
    if (props.tips) {
      if (state.datas === INITIAL_STATE.datas) {
        newState = {...newState, datas: props.tips};
      }
      if (props.tips !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {...newState, datas: props.tips}
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(TIP_ADD);
  };

  handleEdit = element => {
    this.props.history.push(TIP_EDIT.replace(':tipId', element.id));
  };

  handleUpOrder = element => {
    let uppedTipIndex = this.state.datas.indexOf(element);
    element.order--;
    let downedTip = this.state.datas[uppedTipIndex - 1];
    downedTip.order++;
    this.props.putTip(
      this.props.authAdmin.token,
      element.id,
      element
    )
      .catch((error) => {
        this.props.handleError(error);
      })
      .then(() => {
        this.props.putTip(
          this.props.authAdmin.token,
          downedTip.id,
          downedTip
        )
          .catch((error) => {
            this.props.handleError(error);
          });
      });
  };

  handleDownOrder = element => {
    let downedTipIndex = this.state.datas.indexOf(element);
    element.order++;
    let uppedTip = this.state.datas[downedTipIndex + 1];
    uppedTip.order--;
    this.props.putTip(
      this.props.authAdmin.token,
      element.id,
      element
    )
      .catch((error) => {
        this.props.handleError(error);
      })
      .then(() => {
        this.props.putTip(
          this.props.authAdmin.token,
          uppedTip.id,
          uppedTip
        )
          .catch((error) => {
            this.props.handleError(error);
          });
      });
  };

  handleDelete = element => {
    this.props.deleteTip(this.props.authAdmin.token, element)
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const columnData = [
      {id: 'order', label: 'Ordre', numeric: false, disablePadding: false},
      {id: 'translations[lang === \'fr\'].title', label: 'Titre',
        numeric: false, disablePadding: false},
      {id: 'imagePath', label: 'Image', numeric: false, image: true, disablePadding: false},
      {id: 'isActive', label: 'Actif', numeric: false, boolean: true, disablePadding: false}
    ];
    return (
      <LargePaper title="Gestion des astuces">
        <div>
          {this.props.pending &&
          <Spinner />
          }
          {!this.props.pending && (
            <CustomTable
              datas={this.state.datas}
              onAdd={this.handleAdd}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              buttonLabel='Ajouter une astuce'
              paginationLabel='Astuces par page'
              emptyDataMessage='Aucune astuce'
              deleteDialogText='Êtes-vous sûr de vouloir supprimer cette astuce ?'
              columnData={columnData}
              sending={this.props.sending}
              showDelete={AuthService.isGranted(ROLE_ADMIN)}
              canReorder
              onUpOrder={this.handleUpOrder}
              onDownOrder={this.handleDownOrder}
            />
          )}
        </div>
      </LargePaper>
    );
  }
}


function mapStateToProps(state) {
  return {
    authAdmin: state.admins.authAdmin,
    tips: state.tips.content,
    pending: state.tips.pending,
    sending: state.tips.sending,
    error: state.tips.error,
  };
}

export default compose(
  withAuthorization('Astuces'),
  connect(mapStateToProps, {getTips, deleteTip, putTip, handleError})
)(TipsPage);
