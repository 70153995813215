import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import {postTip, putTip, getTips, postTipPicture} from "../../stores/tips/actions";
import {handleError} from "../../stores/infos/actions";
import {TIPS} from "../../constants/routes";
import Spinner from "../common/Spinner";

import CustomForm from "../common/form/CustomForm";

// Intial State of the Component
const INITIAL_STATE = {
  id:'',
  imagePath: '',
  isActive: true,
  translations: [],
  order: '',
  pictureToUpload: null,
  sending: false
};

const stateWithTip = tip => ({
  id: tip.id,
  imagePath: tip.imagePath,
  isActive: tip.isActive,
  order: tip.order,
  translations: tip.translations
});

export class TipForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    tips: PropTypes.array,
    postTip: PropTypes.func.isRequired,
    putTip: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static defaultProps = {
    tips: null
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.tips && !props.pending && props.token && props.match && props.match.params.tipId) {
      props.getTips(props.token);
    }
    if (props.tips && props.match && props.match.params.tipId && state.id === INITIAL_STATE.id) {
      const tips = props.tips;
      const currentTipId = props.match.params.tipId;
      const foundTip = tips.find(tip => tip.id === currentTipId);

      if (foundTip) {
        newState = {...newState, ...stateWithTip(foundTip)};
      }
    }

    if (props.sending && !state.sending) { // Start sending
      return {sending: true};
    }
    if (!props.sending && state.sending) { // End sending
      if (state.pictureToUpload && state.id) { // picture To Upload after deal sent
        props.postTipPicture(props.token, state.id, state.pictureToUpload, (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(state.pictureToUpload.name + ' -> '
            + percentCompleted + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
        });
        return {
          pictureToUpload: null,
          sending: false
        };
      } else if (!state.pictureToUpload) {
        props.history.push(TIPS);
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {

    // Retrieve ImageToUpload if available
    if (formState.pictureToUpload) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload: formState.pictureToUpload
      });
    }

    if (this.state.id === INITIAL_STATE.id) {
      // Set order when posting
      formState = {...formState, order: this.props.tips.length};
      this.props.postTip(
        this.props.token,
        formState
      )
        .then((tipId => {
          if (this.state.pictureToUpload) {
            this.setState({id: tipId});
          }
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    } else {
      this.props.putTip(
        this.props.token,
        this.state.id,
        formState
      )
        .then(tipId => {
          if (this.state.pictureToUpload) {
            this.setState({id: tipId});
          }
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
    event.preventDefault();
  };

  render() {

    const {pending, sending} = this.props;

    const {id} = this.state;

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === ''  ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    const formFieldsFR = [
      {id: 'block_fr_title', type:'title', label:'Traduction française', noPadding:true},
      {id: 'divider_fr', type:'divider', noPadding:true},
      {id: 'title', type:'textField', gridMD:6, label: 'Titre',
        isMandatory:true},
      {id: 'content', type:'richText', label: 'Contenu',
        isMandatory:true},
    ];

    const formFieldsEN = [
      {id: 'block_en_title', type:'title', label:'Traduction anglaise', noPadding:true},
      {id: 'divider_en', type:'divider', noPadding:true},
      {id: 'title', type:'textField', gridMD:6, label: 'Titre',
        isMandatory:true},
      {id: 'content', type:'richText', label: 'Contenu',
        isMandatory:true},
    ];

    const formFields = [
      {id: 'block_display', type:'title', label:'Options d\'affichage', noPadding:true},
      {id: 'divider_display', type:'divider', noPadding:true},
      {id: 'imagePath', picture: 'pictureToUpload', gridMD:6,
        type:'pictureDropzone', isMandatory:false},
      {id: 'isActive', type:'checkboxField', gridMD:6, label: 'Actif', isMandatory:true},

      {id: 'translations[lang === \'fr\']', type: 'subform', options:formFieldsFR},

      {id: 'translations[lang === \'en\']', type: 'subform', options:formFieldsEN},

    ];

    const customForm = (
      <CustomForm
        data={this.state}
        formFields={formFields}
        buttonLabel={buttonLabel}
        onSubmit={this.onSubmit}
        sending={sending}
      />
    );

    if (pending) {
      return (load);
    } else {
      return (customForm);
    }
  }
}

function mapStateToProps(state) {
  return {
    token: state.admins.authAdmin.token,
    tips: state.tips.content,
    pending: state.tips.pending,
    sending: state.tips.sending
  };
}

export default compose(
  connect(mapStateToProps, {getTips, postTip, postTipPicture, putTip, handleError}),
)(TipForm);
