import * as types from './types';
import ApiService from '../../services/ApiService';
import AuthService from '../../services/AuthService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const adminsPendingAction = () => ({
  type: types.ADMINS_PENDING,
});

export const adminRefreshPendingAction = () => ({
  type: types.ADMIN_REFRESH_PENDING,
});

export const adminSendingAction = () => ({
  type: types.ADMIN_SENDING,
});

export const adminsDownloadedAction = admins => ({
  type: types.ADMINS_DOWNLOADED,
  payload: admins,
});

export const adminDownloadedAction = (admin) => ({
  type: types.ADMIN_DOWNLOADED,
  payload: admin
});

export const adminRemovedAction = (admin) => ({
  type: types.ADMIN_REMOVED,
  payload: admin,
});

export const adminLoginAction = admin => ({
  type: types.ADMIN_LOGIN,
  payload: admin,
});

export const adminRefreshAction = admin => ({
  type: types.ADMIN_REFRESH,
  payload: admin,
});

export const adminUpdateAction = admin => ({
  type: types.ADMIN_UPDATE,
  payload: admin,
});

export const adminsFailedAction = error => ({
  type: types.ADMINS_FAILED,
  payload: error,
});

export const adminLogoutAction = () => ({
  type: types.ADMIN_LOGOUT
});


// --------------------------------
// Actions

// Login
export function login(email, password) {
  return (dispatch) => {
    dispatch(adminsPendingAction());

    const admin = {
      username: email,
      password: password
    };

    return ApiService
      .login(admin)
      .then((data) => {
        AuthService.setSession(data);
        dispatch(adminLoginAction(data));
      })
      .catch((error) => {
        AuthService.logout();
        dispatch(adminsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function refresh(refreshToken) {
  return (dispatch) => {
    dispatch(adminRefreshPendingAction());
    const content = {
      refresh_token: refreshToken
    };
    return ApiService
      .refresh(content)
      .then((data) => {
        AuthService.setSession(data);
        dispatch(adminRefreshAction(data));
      })
      .catch((error) => {
        dispatch(adminsFailedAction(error));
        dispatch(handleError(error));
      });
  }
}

// Mets à jour l'admin en fonction de la session courante
export function update(admin) {
  return (dispatch) => {
    dispatch(adminUpdateAction(admin));
  }
}

// Récupèration de la liste des admin
export function getAdmins(token) {
  return (dispatch) => {
    dispatch(adminsPendingAction());
    return ApiService
      .getAdmins(token)
      .then((data) => {
        dispatch(adminsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(adminsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'un admin
export function postAdmin(token, admin) {
  return (dispatch) => {
    dispatch(adminSendingAction());

    return ApiService
      .postAdmin(token, admin)
      .then((data) => {
        dispatch(adminDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(adminsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Edition d'un admin
export function putAdmin(token, adminId, admin) {
  return (dispatch) => {
    dispatch(adminSendingAction());

    return ApiService
      .putAdmin(token, adminId, admin)
      .then((data) => {
        const updatedAdmin = {
          ...data,
          id: adminId,
        };
        dispatch(adminDownloadedAction(updatedAdmin));
      })
      .catch((error) => {
        dispatch(adminsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Suppression d'un admin
export function deleteAdmin(token, admin) {
  return (dispatch) => {
    dispatch(adminSendingAction());
    return ApiService
      .deleteAdmin(token, admin.id)
      .then(() => {
        dispatch(adminRemovedAction(admin));
      })
      .catch((error) => {
        dispatch(adminsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

/**
 * logout
 *
 * @returns {function(*)}
 */
export function logout() {
  return (dispatch) => {
    dispatch(adminLogoutAction());
  }
}
