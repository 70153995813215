import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import {
  getThemes,
  postThemeBloc,
  postThemeBlocPicture,
  putThemeBloc
} from "../../stores/themes/actions";
import {handleError} from "../../stores/infos/actions";
import {THEME_EDIT} from "../../constants/routes";
import Spinner from "../common/Spinner";

import StringService from "../../services/StringService";
import CustomForm from "../common/form/CustomForm";

// Tags
const TAGS = {
  events: "events",
  webcams: "webcams",
  activity: "activity",
  directory: "directory",
  parking: "parking",
  social: "social",
  pistes: "pistes",
  guiding: "guiding",
  bus: "bus",
  skiplus: "skiplus"
};

const LINKS = {
  TYPE_LINK: "Lien",
  TYPE_NATIVE: "Natif"
};

// Intial State of the Component
const INITIAL_STATE = {
  id: '',
  order: '',
  type: null,
  link: '',
  timeAvailable: StringService.timeTimestampToString(new Date() / 1000),
  dateAvailable: StringService.dateTimestampToString(new Date() / 1000),
  titleColor: '#ff0000',
  subtitleColor: '#ff0000',
  shadowColor: '#ff0000',
  hasShadow: false,
  imagePath: '',
  title_fr: '',
  subtitle_fr: '',
  unavailability_message_fr: '',
  title_en: '',
  subtitle_en: '',
  unavailability_message_en: '',
  pictureToUpload: null,
  sending: false
};

const stateWithThemeBloc = themeBloc => ({
  id: themeBloc.id,
  order: themeBloc.order,
  type: themeBloc.type,
  link: themeBloc.link,
  timeAvailable: StringService.timeTimestampToString(themeBloc.availabilityDate),
  dateAvailable: StringService.dateTimestampToString(themeBloc.availabilityDate),
  titleColor: themeBloc.titleColor,
  subtitleColor: themeBloc.subtitleColor,
  shadowColor: themeBloc.shadowColor,
  hasShadow: themeBloc.hasShadow,
  imagePath: themeBloc.imagePath,
  title_fr: themeBloc.translations.find(translation => translation.lang === 'fr').title,
  subtitle_fr: themeBloc.translations.find(translation => translation.lang === 'fr').subtitle,
  unavailability_message_fr: themeBloc.translations.find(translation => translation.lang === 'fr')
    .unavailabilityMessage,
  title_en: themeBloc.translations.find(translation => translation.lang === 'en').title,
  subtitle_en: themeBloc.translations.find(translation => translation.lang === 'en').subtitle,
  unavailability_message_en: themeBloc.translations.find(translation => translation.lang === 'en')
    .unavailabilityMessage,
});

export class ThemeBlocForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        themeId: PropTypes.string,
      })
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    postThemeBloc: PropTypes.func.isRequired,
    putThemeBloc: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.themes && !props.pending && props.token
      && props.match && props.match.params.themeId) {
      props.getThemes(props.token);
    }
    if (props.themes && props.match && props.match.params.themeId
      && state.id === INITIAL_STATE.id) {

      const themes = props.themes;
      const currentThemeId = props.match.params.themeId;
      const foundTheme = themes.find(theme => theme.id === currentThemeId);

      newState = {...newState, themeBlocs: foundTheme.themeBlocs};

      if (props.match.params.themeBlocId) {
        const foundThemeBloc = foundTheme.themeBlocs
          .find(themeBloc => themeBloc.id === props.match.params.themeBlocId);
        if (foundThemeBloc) {
          newState = {...newState, ...stateWithThemeBloc(foundThemeBloc)};
        }
      }
    }

    if (props.sending && !state.sending) { // Start sending
      return {sending: true};
    }
    if (!props.sending && state.sending) { // End sending
      if (state.pictureToUpload && state.id) { // picture To Upload after deal sent
        props.postThemeBlocPicture(props.token, state.themeId, state.id, state.pictureToUpload,
          (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(state.pictureToUpload.name + ' -> ' + percentCompleted
              + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
          });
        return {
          pictureToUpload: null,
          sending: false
        };
      } else if (!state.pictureToUpload) {
        props.history.push(THEME_EDIT.replace(':themeId', props.match.params.themeId));
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      themeId: props.match.params.themeId
    };
  }

  onSubmit = (event, formState) => {
    let translations = [
      {
        lang: 'fr', title: formState.title_fr, subtitle: formState.subtitle_fr,
        unavailabilityMessage: formState.unavailability_message_fr
      },
      {
        lang: 'en', title: formState.title_en, subtitle: formState.subtitle_en,
        unavailabilityMessage: formState.unavailability_message_en
      }
    ];

    // Add translations to object
    formState = {...formState, translations};
    // HandleDate
    formState = {
      ...formState,
      availabilityDate: StringService
        .dateTimeStringToTimestamp(formState.dateAvailable + 'T' + formState.timeAvailable)
    };
    if (formState.pictureToUpload) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload: formState.pictureToUpload
      });
    }
    if (this.state.id === INITIAL_STATE.id) {
      // Set order when posting
      formState = {...formState, order: this.state.themeBlocs.length};
      this.props.postThemeBloc(
        this.props.token,
        this.state.themeId,
        formState
      ).then((themeBlocId => {
          if (this.state.pictureToUpload) {
            this.setState({id: themeBlocId});
          }
        })
      ).catch((error) => {
        this.props.handleError(error);
      });
    } else {
      this.props.putThemeBloc(
        this.props.token,
        this.state.themeId,
        this.state.id,
        formState
      )
        .then(themeBlocId => {
          if (this.state.pictureToUpload) {
            this.setState({id: themeBlocId});
          }
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
    event.preventDefault();
  };

  render() {

    const {pending, sending} = this.props;
    const {id} = this.state;

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === '' ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    const formFields = [
      {
        id: 'imagePath', picture: 'pictureToUpload',
        type: 'pictureDropzone', gridMD: 6, isMandatory: false
      },
      {
        id: 'dateAvailable', type: 'textField', subtype: 'date', gridMD: 3, label: 'Date',
        isMandatory: true
      },
      {
        id: 'timeAvailable', type: 'textField', subtype: 'time', gridMD: 3, label: 'Heure',
        isMandatory: true
      },
      {id: 'titleColor', type: 'input', gridMD: 6, label: 'Titre', isMandatory: true},
      {
        id: 'subtitleColor', type: 'input', gridMD: 6, label: 'Sous-titre',
        isMandatory: true
      },

      {id: 'hasShadow', type: 'checkboxField', gridMD: 6, label: 'Ombre', isMandatory: true},
      {
        id: 'shadowColor', type: 'input', gridMD: 6, condition: 'hasShadow',
        label: 'Ombre', isMandatory: true
      },
      {
        id: 'type', type: 'selectField', options: LINKS,
        gridMD: 6, label: 'Type', isMandatory: true
      },
      {
        id: 'link', type: 'selectField', options: TAGS,
        condition: 'type===TYPE_NATIVE', gridMD: 6, label: 'Lien', isMandatory: true
      },
      {
        id: 'link',
        type: 'textField',
        condition: 'type===TYPE_LINK',
        gridMD: 6,
        label: 'Lien ( Doit commencer par http(s):// ) ',
        isMandatory: true
      },
      {id: 'title_fr', type: 'textField', gridMD: 6, label: 'Titre FR', isMandatory: true},
      {id: 'subtitle_fr', type: 'textField', gridMD: 6, label: 'Sous-titre FR', isMandatory: false},
      {
        id: 'unavailability_message_fr', type: 'textField', gridMD: 6,
        label: 'Message d\'indisponibilité FR', isMandatory: false
      },
      {id: 'title_en', type: 'textField', gridMD: 6, label: 'Titre EN', isMandatory: false},
      {id: 'subtitle_en', type: 'textField', gridMD: 6, label: 'Sous-titre EN', isMandatory: false},
      {
        id: 'unavailability_message_en', type: 'textField', gridMD: 6,
        label: 'Message d\'indisponibilité EN', isMandatory: false
      },
    ];

    const customFrom = (
      <CustomForm
        data={this.state}
        formFields={formFields}
        buttonLabel={buttonLabel}
        onSubmit={this.onSubmit}
        sending={sending}
      />
    );

    if (pending) {
      return (load);
    } else {
      return (customFrom);
    }
  }
}

function mapStateToProps(state) {
  return {
    token: state.admins.authAdmin.token,
    themes: state.themes.content,
    pending: state.themes.pending,
    sending: state.themes.sending
  };
}

export default compose(
  connect(mapStateToProps,
    {getThemes, postThemeBloc, putThemeBloc, postThemeBlocPicture, handleError}),
)(ThemeBlocForm);
