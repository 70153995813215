export const HOMEPAGE = '/';
export const CONFIG = '/configurations';
export const THEME = '/themes';
export const ALERT = '/alerts';
export const ALERT_ADD = '/alerts/add/:presetId';
export const ALERT_EDIT = '/alerts/:alertId';
export const TIPS = '/tips';
export const TIP_ADD = '/tips/add';
export const TIP_EDIT = '/tips/:tipId';
export const PAGES = '/pages';
export const PAGE_EDIT = '/pages/:pageId';
export const PAGE_BLOC_ADD = '/pages/:pageId/bloc/add';
export const PAGE_BLOC_EDIT = '/pages/:pageId/bloc/:pageBlocId';
export const THEMES = '/themes';
export const THEME_ADD = '/themes/add';
export const THEME_EDIT = '/themes/:themeId';
export const THEME_BLOC_ADD = '/themes/:themeId/bloc/add';
export const THEME_BLOC_EDIT = '/themes/:themeId/bloc/:themeBlocId';
export const ADMINS = '/admins';
export const ADMIN_ADD = '/admins/add';
export const ADMIN_EDIT = '/admins/:adminId';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_CHANGE = '/pw-change/:token';
export const IPHONE_NOTIFICATION_VIEW = '/iphone/notification';
export const IPHONE_ALERTS_VIEW = '/iphone/alerts';
export const IPHONE_ALERT_VIEW = '/iphone/alert';
export const NOT_FOUND = '/notfound';
