import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import tabsos from "../../Resources/images/iphone/tabbar_sos.png";
import tabalert from "../../Resources/images/iphone/tabbar_alert.png";
import tabmto from "../../Resources/images/iphone/tabbar_my_tignes_open.png";
import tabbp from "../../Resources/images/iphone/tabbar_bike_park.png";
import tabtip from "../../Resources/images/iphone/tabbar_info.png";
import {TIG_TOOLBAR_LIGHT_GREY} from "../../constants/colors";

const styles = () => ({
  footer: {
    height: '4em',
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    backgroundColor: TIG_TOOLBAR_LIGHT_GREY,
    justifyContent: 'space-around',
    flexWrap: 'nowrap',
    paddingTop: '0.5em'
  },
  footerItem: {
  height: '3em',
}
});

export const IphoneTabbar = (props) => {
  let {classes} = props;
  return (
    <div className={classes.footer}>
      <img alt="sos" src={tabsos} className={classes.footerItem} />
      <img alt="alert" src={tabalert} className={classes.footerItem} />
      <img alt="mytignesconnect" src={tabmto} className={classes.footerItem} />
      <img alt="bikepark" src={tabbp} className={classes.footerItem} />
      <img alt="info" src={tabtip} className={classes.footerItem} />
    </div>
  )
};

IphoneTabbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IphoneTabbar);