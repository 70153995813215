import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import TipForm from "../../components/forms/TipForm";
import LargePaper from "../../components/common/LargePaper";

export const TipAddPage = props => {
  return (
    <LargePaper title="Ajouter une astuce">
      <TipForm history={props.history} />
    </LargePaper>
  );
};

TipAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withAuthorization('Astuces')(TipAddPage);
