export const ROLES = {
  notifier: [
    "ROLE_NOTIFIER", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"
  ],
  admin: [
    "ROLE_ADMIN", "ROLE_SUPER_ADMIN"
  ],
  super_admin: [
    "ROLE_SUPER_ADMIN"
  ]
};

export const ROLE_NOTIFIER = "notifier";
export const ROLE_ADMIN = "admin";
export const ROLE_SUPER_ADMIN = "super_admin";
