import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import LargePaper from "../../components/common/LargePaper";
import AlertForm from "../../components/forms/AlertForm";

export const AlertAddPage = props => {

  let presetId = props.match.params.presetId;

  return (
    <LargePaper title="Créer une alerte">
      <AlertForm history={props.history} presetId={presetId} />
    </LargePaper>
  );
};

AlertAddPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Alertes')(AlertAddPage);