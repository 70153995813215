import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import 'website-mobile-preview/biomp';
import iphoneImage from "website-mobile-preview/images/iphone6_front_black.png";

import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Divider from "@material-ui/core/Divider/Divider";

import {handleError} from "../../stores/infos/actions";
import {getAlertPresets} from "../../stores/alertPresets/actions";
import {postAlert, putAlert, getAlerts} from "../../stores/alerts/actions";
import Spinner from "../common/Spinner";
import stylesDefault from "../../constants/stylesDefault";
import {
  ALERT,
  IPHONE_ALERT_VIEW,
  IPHONE_ALERTS_VIEW,
  IPHONE_NOTIFICATION_VIEW
} from "../../constants/routes";
import StringService from "../../services/StringService";
import CustomForm from "../common/form/CustomForm";

const INITIAL_STATE = {
  sending: false,
  pending: true,
  scheduleForLater: false,
  timePublish: StringService.timeTimestampToString(new Date() / 1000),
  datePublish: StringService.dateTimestampToString(new Date() / 1000),
  presetId: "",
  title_fr: null,
  notificationText_fr: null,
  content_fr: null,
  subtitle_fr: null,
  title_en: null,
  notificationText_en: null,
  content_en: null,
  subtitle_en: null,
  group: '',
  isGeolocated: false,
  notificationEnable: true,
  dialogOpen: false,
  l1Ref: React.createRef(),
  l2Ref: React.createRef(),
  l3Ref: React.createRef(),
};

const pushGroup = {
  powder_alarm: 'Alertes',
  parking: 'Flash Info',
  circulation: 'Info Route',
  pistes_remontees: 'Info Domaine Skiable',
  evenements: 'Info événements et animations',
  activities_information: 'Info Activités',
  bike_park: 'Bike Park',
};

class AlertForm extends React.Component {

  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    presetId: PropTypes.string.isRequired,
    alertPresets: PropTypes.array,
    pending: PropTypes.bool.isRequired,
    postAlert: PropTypes.func.isRequired,
    putAlert: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    alert: PropTypes.object
  };

  static defaultProps = {
    alertPresets: null,
    alert: null
  };

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.token && !props.alertPresets && !props.presetsPending) {
      props.getAlertPresets(props.token);
    }
    if (props.alert && props.alert.translations && state.pending) {
      newState = {...newState, pending: false};
      newState = AlertForm.setTranslation(newState, props.alert.translations);
      newState = {...newState, group: props.alert.notification.group};
      newState = {...newState, isGeolocated: props.alert.notification.isGeolocated};
      newState = {...newState, scheduleForLater: props.alert.publishDate !== null};
      newState = {
        ...newState,
        timePublish: StringService.timeTimestampToString(props.alert.publishDate),
        datePublish: StringService.dateTimestampToString(props.alert.publishDate),
      };
      newState = {...newState, presetId: props.alert.presetId};
    } else if (props.alertPresets && state.pending) {
      newState = {...newState, pending: false};
      newState = {...newState, presetId: props.presetId};
      let isPreset = function (p) {
        return p.id === props.presetId;
      };
      let preset = props.alertPresets.find(function (p) {
        return isPreset(p);
      });
      if (preset) {
        newState = AlertForm.setTranslation(newState, preset.translations);
        newState = {...newState, group: preset.group};
      }
    }
    return newState;
  }

  static setTranslation(newState, translations) {
    translations.forEach(function (translation) {
      for (let property in translation) {
        if (property !== 'lang') {
          let propertyName = property + '_' + translation.lang;
          newState = {...newState, [propertyName]: translation[property]};
        }
      }
    });
    return newState;
  }

  static updateIphoneViews(state) {
    try {
      const defaultScale = 0.7;
      const encodeGetParams = p =>
        Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

      const queries = {
        notification: state.notificationText_fr,
        title: state.title_fr,
        content: state.content_fr,
      };
      const urlParams = '?' + encodeGetParams(queries);
      let iphoneParams = [
        {ref: state.l1Ref, url: IPHONE_NOTIFICATION_VIEW},
        {ref: state.l2Ref, url: IPHONE_ALERTS_VIEW},
        {ref: state.l3Ref, url: IPHONE_ALERT_VIEW}
      ];
      iphoneParams.forEach(function (element) {
        window.bioMp(
          element.ref.current,
          {
            url: element.url + urlParams,
            image: iphoneImage,
            scale: defaultScale
          }
        );
        if (element.ref.current.childElementCount > 1) {
          let first = element.ref.current.firstChild;
          element.ref.current.removeChild(first);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  constructor(props) {
    super(props);
    let dateString = (new Date()).toISOString();
    this.state = {
      ...INITIAL_STATE,
      publishDate: dateString.slice(0, dateString.lastIndexOf(':'))
    };
  }

  componentDidMount() {
    AlertForm.updateIphoneViews(this.state);
  }

  onSubmit = (event, formState) => {
    this.setState({dialogOpen: true, formState: formState});
    event.preventDefault();
  };

  getAlertFromState(state) {
    let publicationDate = (state.scheduleForLater) ?
      StringService.dateTimeStringToTimestamp(state.datePublish + 'T' + state.timePublish)
      : null;
    if (publicationDate) {
      publicationDate = new Date(publicationDate).getTime();
    }
    return {
      presetId: this.state.presetId,
      publishDate: publicationDate,
      translations: [
        {
          lang: "fr",
          title: state.title_fr,
          notificationText: state.notificationText_fr,
          content: state.content_fr,
          subtitle: state.subtitle_fr
        },
        {
          lang: "en",
          title: state.title_en,
          notificationText: state.notificationText_en,
          content: state.content_en,
          subtitle: state.subtitle_en
        }
      ],
      notification: {
        group: state.group,
        isGeolocated: state.isGeolocated,
      }
    };
  }

  handleValidate = (event) => {
    let alert = this.getAlertFromState(this.state.formState);
    if (this.props.alert) {
      this.props.putAlert(this.props.token, this.props.alert.id, alert).then(() => {
        this.props.history.push(ALERT);
      });
    } else {
      this.props.postAlert(this.props.token, alert).then(() => {
        this.props.history.push(ALERT);
      });
    }
    event.preventDefault();
  };

  handleClose = (event) => {
    this.setState({dialogOpen: false});
    event.preventDefault();
  };

  render() {
    const {pending, alertPresets, alert} = this.props;
    const {sending} = this.state;

    const dialog = (
      <Dialog
        open={this.state.dialogOpen}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        keepMounted
      >
        <DialogTitle id="alert-dialog-title">
          Attention
        </DialogTitle>
        <DialogContent>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Valider la création de l'alerte et l'envoi de la notification ?
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary" variant="contained">
            Annuler
          </Button>
          <Button onClick={this.handleValidate} color="primary" variant="contained" autoFocus>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    );

    const phoneGrid = (
      <div>
        <Divider />
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Aperçu
            </Typography>
          </Grid>
          {[this.state.l1Ref, this.state.l2Ref, this.state.l3Ref].map(
            function (element) {
              return (
                <Grid item xs={12} md={4} style={stylesDefault.center}>
                  <div ref={element} />
                </Grid>
              )
            }
          )}
        </Grid>
      </div>
    );

    const load = (<Spinner />);

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : (alert) ? 'Valider' : 'Envoyer';

    let finishedAlert = false;
    if (alert && alert.notification) {
      finishedAlert = alert.notification.isSent;
    }
    const formFields = [
      {id: 'block_fr_title', type: 'title', label: 'Traduction française', noPadding: true},
      {id: 'divider_fr', type: 'divider', noPadding: true},
      {
        id: 'title_fr', type: 'textField', gridMD: 6, label: 'Titre',
        onChange: state => AlertForm.updateIphoneViews(state), isMandatory: true
      },
      {id: 'subtitle_fr', type: 'textField', gridMD: 6, label: 'Sous-titre', isMandatory: false},
      {
        id: 'notificationText_fr', type: 'textField', gridMD: 12,
        label: 'Notification sur écran verrouillé',
        onChange: state => AlertForm.updateIphoneViews(state), isMandatory: true
      },
      {
        id: 'content_fr', type: 'richText', gridMD: 12, label: 'Contenu',
        onChange: state => AlertForm.updateIphoneViews(state), isMandatory: false
      },

      {id: 'block_en_title', type: 'title', label: 'Traduction anglaise', noPadding: true},
      {id: 'divider_en', type: 'divider', noPadding: true},
      {id: 'title_en', type: 'textField', gridMD: 6, label: 'Titre', isMandatory: true},
      {id: 'subtitle_en', type: 'textField', gridMD: 6, label: 'Sous-titre', isMandatory: false},
      {
        id: 'notificationText_en', type: 'textField', gridMD: 12,
        label: 'Notification sur écran verrouillé', isMandatory: true
      },
      {id: 'content_en', type: 'richText', gridMD: 12, label: 'Contenu', isMandatory: false},

      {id: 'block_push', type: 'title', label: 'Notification', noPadding: true},
      {id: 'divider_push', type: 'divider', noPadding: true},
      {
        id: 'group', type: 'selectField', options: pushGroup, disabled: finishedAlert,
        gridMD: 6, label: 'Groupe de notification', isMandatory: true
      },
      {
        id: 'isGeolocated', type: 'checkboxField', gridMD: 6, label: 'Géolocaliser la notification',
        disabled: finishedAlert, isMandatory: false
      },
      {
        id: 'scheduleForLater',
        type: 'checkboxField',
        gridMD: 6,
        label: 'Programmer la notification',
        disabled: finishedAlert,
        isMandatory: false
      },
      {
        id: 'datePublish',
        type: 'textField',
        subtype: 'date',
        gridMD: 3,
        label: 'Date',
        condition: 'scheduleForLater',
        disabled: finishedAlert,
        isMandatory: false
      },
      {
        id: 'timePublish',
        type: 'textField',
        subtype: 'time',
        gridMD: 3,
        label: 'Heure',
        condition: 'scheduleForLater',
        disabled: finishedAlert,
        isMandatory: false
      }
    ];

    const customForm = (
      <div>
        <CustomForm
          data={this.state}
          formFields={formFields}
          buttonLabel={buttonLabel}
          onSubmit={this.onSubmit}
          sending={sending}
        />
        {phoneGrid}
        {dialog}
      </div>
    );

    if (pending || !alertPresets) {
      return (load);
    } else {
      return (customForm);
    }
  }
}

function mapStateToProps(state) {
  return {
    token: state.admins.authAdmin.token,
    alerts: state.alerts.content,
    alertPresets: state.alertPresets.content,
    pending: state.alerts.pending,
    presetsPending: state.alertPresets.pending,
    sending: state.alerts.sending,
  };
}

export default connect(
  mapStateToProps,
  {getAlertPresets, postAlert, putAlert, getAlerts, handleError}
)(AlertForm);
