import React from 'react';
import PropTypes from "prop-types";
import {compose} from "recompose";
import {
  BrowserRouter as Router,
  Route, Switch
} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import SnackBar from "../components/common/SnackBar";
import './App.css';
import withRoot from "../hocs/withRoot";
import Error404 from "./Error404";
import * as routes from "../constants/routes";
import SignInPage from "./SignInPage";
import PasswordForgetPage from "./PasswordForgetPage";
import PasswordChangePage from "./PasswordChangePage";
import HomePage from "./HomePage";
import ConfigurationPage from "./ConfigurationPage";
import AdminPage from "./admin/AdminPage";
import AdminAddPage from "./admin/AdminAddPage";
import AdminEditPage from "./admin/AdminEditPage";
import TipsPage from "./tips/TipsPage";
import TipAddPage from "./tips/TipAddPage";
import TipEditPage from "./tips/TipEditPage";
import ThemesPage from "./themes/ThemesPage";
import ThemeAddPage from "./themes/ThemeAddPage";
import ThemeEditPage from "./themes/ThemeEditPage";
import ThemeBlocAddPage from "./themes/ThemeBlocAddPage";
import ThemeBlocEditPage from "./themes/ThemeBlocEditPage";
import WebPagesPage from "./webPages/WebPagesPage";
import WebPageEditPage from "./webPages/WebPageEditPage";
import WebPageBlocAddPage from "./webPages/WebPageBlocAddPage";
import WebPageBlocEditPage from "./webPages/WebPageBlocEditPage";
import AlertPage from "./alerts/AlertPage";
import AlertAddPage from "./alerts/AlertAddPage";
import AlertEditPage from "./alerts/AlertEditPage";
import IphoneNotificationPage from "./alerts/IphoneNotificationPage";
import IphoneAlertsPage from "./alerts/IphoneAlertsPage";
import IphoneAlertPage from "./alerts/IphoneAlertPage";


const styles = () => ({
  root: {
    display: 'flex',
    position: 'relative',

    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden'
  },
  content: {
    flexGrow: 1
  }
});

const App = (props) => {
  const { classes } = props;
  return (
    <Router>
      <div className={classes.content}>
        <SnackBar />
        <Switch>
          <Route exact path={routes.SIGN_IN} component={SignInPage} />
          <Route exact path={routes.PASSWORD_FORGET} component={PasswordForgetPage} />
          <Route exact path={routes.PASSWORD_CHANGE} component={PasswordChangePage} />
          <Route exact path={routes.HOMEPAGE} component={HomePage} />
          <Route exact path={routes.CONFIG} component={ConfigurationPage} />
          <Route exact path={routes.ALERT} component={AlertPage} />
          <Route exact path={routes.ALERT_ADD} component={AlertAddPage} />
          <Route exact path={routes.ALERT_EDIT} component={AlertEditPage} />
          <Route exact path={routes.THEMES} component={ThemesPage} />
          <Route exact path={routes.THEME_ADD} component={ThemeAddPage} />
          <Route exact path={routes.THEME_EDIT} component={ThemeEditPage} />
          <Route exact path={routes.THEME_BLOC_ADD} component={ThemeBlocAddPage} />
          <Route exact path={routes.THEME_BLOC_EDIT} component={ThemeBlocEditPage} />
          <Route exact path={routes.PAGES} component={WebPagesPage} />
          <Route exact path={routes.PAGE_EDIT} component={WebPageEditPage} />
          <Route exact path={routes.PAGE_BLOC_ADD} component={WebPageBlocAddPage} />
          <Route exact path={routes.PAGE_BLOC_EDIT} component={WebPageBlocEditPage} />
          <Route exact path={routes.TIPS} component={TipsPage} />
          <Route exact path={routes.TIP_ADD} component={TipAddPage} />
          <Route exact path={routes.TIP_EDIT} component={TipEditPage} />
          <Route exact path={routes.ADMINS} component={AdminPage} />
          <Route exact path={routes.ADMIN_ADD} component={AdminAddPage} />
          <Route exact path={routes.ADMIN_EDIT} component={AdminEditPage} />
          <Route exact path={routes.IPHONE_NOTIFICATION_VIEW} component={IphoneNotificationPage} />
          <Route exact path={routes.IPHONE_ALERTS_VIEW} component={IphoneAlertsPage} />
          <Route exact path={routes.IPHONE_ALERT_VIEW} component={IphoneAlertPage} />
          <Route exact path={routes.NOT_FOUND} component={Error404} />
          <Route component={Error404} />
        </Switch>
      </div>
    </Router>
  );
};

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withRoot
)(App);
