/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import {compose} from "recompose";
import {connect} from "react-redux";
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {getAlertPresets} from "../../stores/alertPresets/actions";
import {getAlertCategories} from "../../stores/alertCategories/actions";
import {getAlerts, deleteAlert} from "../../stores/alerts/actions";
import {handleError} from "../../stores/infos/actions";
import LargePaper from "../../components/common/LargePaper";
import withAuthorization from "../../hocs/withAuthorization";
import Spinner from "../../components/common/Spinner";
import {ALERT_ADD, ALERT_EDIT} from "../../constants/routes";
import CustomTable from "../../components/common/table/CustomTable";
import AuthService from "../../services/AuthService";
import CustomSelectField from "../../components/common/form/CustomSelectField";
import {ROLE_NOTIFIER} from "../../constants/roles";

const styles = (theme) => ({
  button: {
    marginRight: theme.spacing.unit,
    display: 'inlnine-block'
  }
});

const INITIAL_STATE = {
  datas: [],
  addModalOpen: false,
  activeStep: 0,
  completed: new Set(),
  category: null,
  categoryPresets: [],
  preset: null
};

function getSteps() {
  return ['Selection d\'une catégorie', 'Selection d\'un modèle'];
}

export class AlertPage extends React.Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getAlertPresets: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getAlertCategories: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getAlerts: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    deleteAlert: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authAdmin: PropTypes.object.isRequired,
    alertPresets: PropTypes.array,
    alertCategories: PropTypes.array,
    fullScreen: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    alertPresets: [],
    alertCategories: [],
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (!props.alertCategories && !props.pending) {
      props.getAlertCategories(props.authAdmin.token);
    }
    if (!props.alertPresets && !props.pending) {
      props.getAlertPresets(props.authAdmin.token);
    }
    if (!props.alerts && !props.pending) {
      props.getAlerts(props.authAdmin.token);
    }
    if (props.alerts && props.alertPresets && props.alertCategories) {
      if (state.datas === INITIAL_STATE.datas) {
        newState = {...newState, datas: props.alerts};
      }
      if (props.alerts !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {...newState, datas: props.alerts}
      }
    }
    return newState;
  }

  handleEdit = element => {
    this.props.history.push(ALERT_EDIT.replace(':alertId', element.id));
  };

  handleDelete = element => {
    this.props.deleteAlert(this.props.authAdmin.token, element)
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  handleDialog = () => {
    this.setState({addModalOpen: !this.state.addModalOpen});
  };

  handleNext = () => {
    if (this.isLastStep() && this.state.preset) {
      this.props.history.push(ALERT_ADD.replace(':presetId', this.state.preset));
    }
    let activeStep = (this.state.activeStep < (getSteps().length - 1)) ?
      this.state.activeStep + 1
      : this.state.activeStep;
    this.setState({
      activeStep,
    });
  };

  handleBack = () => {
    if (this.isFirstStep()) {
      this.handleReset();
      this.handleDialog();
    } else {
      this.setState(state => ({
        activeStep: state.activeStep - 1,
      }));
    }
  };

  handleStep = step => () => {
    this.setState({
      activeStep: step,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: new Set(),
      category: null,
      categoryPresets: [],
      //preset: null
    });
  };

  handleCategoryChange = (event) => {
    this.setState({category: event.target.value});
    let isCategoyPreset = (preset) => {
      return preset.categoryId === event.target.value;
    };
    let newPresets = this.props.alertPresets.filter((value) => {
      return isCategoyPreset(value)
    });
    this.setState({categoryPresets: newPresets});
    this.handleNext();
  };

  totalSteps = () => {
    return getSteps().length;
  };

  isStepComplete(step) {
    return this.state.completed.has(step);
  }

  completedSteps() {
    return this.state.completed.size;
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  isFirstStep() {
    return this.state.activeStep === 0;
  }


  render() {

    const {classes, fullScreen, alertCategories, alertPresets} = this.props;
    const {activeStep, category, preset, categoryPresets} = this.state;
    const steps = getSteps();
    const columnData = [
      {
        id: 'translations[lang === \'fr\'].title',
        label: 'Titre',
        specialOrderBy: 'title',
        numeric: false,
        disablePadding: false
      },
      {
        id: 'publishDate',
        label: 'Date de publication',
        dateTime: true,
        numeric: false,
        disablePadding: false
      },
      {
        id: 'presetId.categoryId.name',
        specialOrderBy: 'name',
        data: [this.props.alertPresets, this.props.alertCategories],
        label: 'Catégorie',
        numeric: false,
        disablePadding: false
      }
    ];

    const categorySelect = alertCategories &&
      (
        <CustomSelectField
          id="category"
          label='Categorie'
          onChange={this.handleCategoryChange}
          value={category}
          gridMD={12}
          fullWidth
          isMandatory
        >
          {alertCategories.map(
            (categ) => {
              return (
                <MenuItem key={categ.id} value={categ.id}>
                  {categ.name}
                </MenuItem>
              );
            }
          )}
        </CustomSelectField>
      );

    const presetSelect = alertCategories && alertPresets && categoryPresets &&
      (
        <CustomSelectField
          id="preset"
          label='Modèle'
          onChange={(event) => this.setState({preset: event.target.value})}
          value={preset}
          fullWidth
          isMandatory
          gridMD={12}
        >
          {categoryPresets.map(
            (preset) => {
              return (
                <MenuItem key={preset.id} value={preset.id}>
                  {preset.name}
                </MenuItem>
              );
            }
          )}
        </CustomSelectField>
      );
    const dialog = (
      <Dialog
        fullScreen={fullScreen}
        fullWidth={!fullScreen}
        open={this.state.addModalOpen}
        onClose={this.handleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Création d'une alerte
        </DialogTitle>
        <DialogContent>
          <Stepper alternativeLabel nonLinear activeStep={activeStep}>
            {steps.map((label, index) => {
              const props = {};
              const buttonProps = {};
              return (
                <Step key={label} {...props}>
                  <StepButton
                    onClick={this.handleStep(index)}
                    completed={this.isStepComplete(index)}
                    {...buttonProps}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === 0 && alertCategories && categorySelect}
          {activeStep === 1 && alertPresets && presetSelect}
        </DialogContent>
        <DialogActions>
          <div>
            <div>
              <Button
                onClick={this.handleBack}
                className={classes.button}
              >
                {(this.isFirstStep() && "Annuler") || "Retour"}
              </Button>
              <Button
                disabled={
                  (!this.isLastStep() && category === null)
                  || (this.isLastStep() && (preset === null || category === null))
                }
                variant="contained"
                color="primary"
                onClick={this.handleNext}
                className={classes.button}
              >
                {(this.isLastStep() && "Terminer") || "Suivant"}
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    );

    return (
      <LargePaper title="Gestion des alertes">
        <div>
          {this.props.pending &&
          <Spinner />
          }
          {!this.props.pending && (
            <div>
              <CustomTable
                datas={this.state.datas}
                onAdd={this.handleDialog}
                onEdit={this.handleEdit}
                onDelete={this.handleDelete}
                deleteExceptId={this.props.authAdmin && this.props.authAdmin.id}
                buttonLabel='Ajouter une alerte'
                paginationLabel='Alertes par page'
                emptyDataMessage='Aucune alerte'
                deleteDialogText='Êtes-vous sûr de vouloir supprimer cette alerte ?'
                columnData={columnData}
                sending={this.props.sending}
                showDelete={AuthService.isGranted(ROLE_NOTIFIER)}
                defaultOrderBy='publishDate'
                defaultOrder='asc'
              />
              {dialog}
            </div>
          )}
        </div>
      </LargePaper>
    )
      ;
  }
}

function mapStateToProps(state) {
  return {
    authAdmin: state.admins.authAdmin,
    alertCategories: state.alertCategories.content,
    alertPresets: state.alertPresets.content,
    alerts: state.alerts.content,
    pending: state.alerts.pending || state.alertCategories.pending || state.alertPresets.pending,
    sending: state.alerts.sending,
    error: state.alerts.error || state.alertCategories.error || state.alertPresets.error,
  };
}

export default compose(
  withMobileDialog(),
  withStyles(styles),
  withAuthorization('Alertes'),
  connect(
    mapStateToProps,
    {getAlertPresets, getAlertCategories, getAlerts, deleteAlert, handleError}
  )
)(AlertPage);
