import React from 'react';
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import queryString from 'query-string';
import {withStyles} from "@material-ui/core";
import Divider from "@material-ui/core/Divider/Divider";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Typography from "@material-ui/core/Typography/Typography";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Icon from "@material-ui/core/Icon/Icon";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import IphoneTabbar from "../../components/iphone/IphoneTabbar";
import appStyles from "../../constants/stylesIphone";

export const IphoneAlertsPage = (props) => {
  let {classes} = props;
  let queries = queryString.parse(props.location.search);
  let text = (queries.title) ? queries.title : 'title';
  let date = new Date();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Icon color="inherit" aria-label="Menu">
            <HomeIcon />
          </Icon>
          <Typography variant="h6" color="inherit" className={classes.appBar}>
            Alertes
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.body}>
        <div className={`${classes.center} ${classes.topPadding}`}>
          <Typography variant="h6">
            Date de dernière mise à jour
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {date.toLocaleString('fr-FR', {timeZone: 'UTC'})}
          </Typography>
        </div>
        <div className={classes.notification}>
          <div className={classes.notificationPart}>
            <div className={classes.notificationHeaderPart}>
              <Typography variant="subtitle1" gutterBottom className={classes.hour}>
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                {date.getHours()}:{date.getMinutes() < 10 ? '0' : ''}{date.getMinutes()}
              </Typography>
            </div>
            <div className={classes.notificationHeaderPart}>
              <Typography variant="subtitle1" gutterBottom className={classes.hour}>
                {text}
              </Typography>
            </div>
          </div>
          <Divider />
        </div>
      </div>
      <IphoneTabbar />
    </div>
  );
};

IphoneAlertsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(appStyles))(IphoneAlertsPage);