import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

export const alertsPendingAction = () => ({
  type: types.ALERTS_PENDING,
});

export const alertSendingAction = () => ({
  type: types.ALERT_SENDING,
});

export const alertsDownloadedAction = (alerts) => ({
  type: types.ALERTS_DOWNLOADED,
  payload: alerts,
});

export const alertsFailedAction = (error) => ({
  type: types.ALERTS_FAILED,
  payload: error,
});

export const alertDownloadedAction = (alert) => ({
  type: types.ALERT_DOWNLOADED,
  payload: alert
});

export const alertRemovedAction = (alert) => ({
  type: types.ALERT_REMOVED,
  payload: alert,
});

export function getAlerts(authUserToken) {
  return (dispatch) => {
    dispatch(alertsPendingAction());
    return ApiService
      .getAlerts(authUserToken)
      .then((data) => {
        dispatch(alertsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(alertsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postAlert(token, alert) {
  return (dispatch) => {
    dispatch(alertSendingAction());
  return ApiService
    .postAlert(token, alert)
    .then((data) => {
      dispatch(alertDownloadedAction(data));
      return data.id
    })
    .catch((error) => {
      dispatch(alertsFailedAction(error));
      dispatch(handleError(error));
    });
  };
}

export function putAlert(token, id, alert) {
  return (dispatch) => {
    dispatch(alertSendingAction());
  return ApiService
    .putAlert(token, id, alert)
    .then((data) => {
      dispatch(alertDownloadedAction(data));
      return data.id
    })
    .catch((error) => {
      dispatch(alertsFailedAction(error));
      dispatch(handleError(error));
    });
  };
}

export function deleteAlert(token, alert) {
  return (dispatch) => {
    dispatch(alertSendingAction());
    return ApiService
      .deleteAlert(token, alert.id)
      .then(() => {
        dispatch(alertRemovedAction(alert));
      })
      .catch((error) => {
        dispatch(alertsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}