import React, {Component} from 'react';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import WebIcon from '@material-ui/icons/Web';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import InfoIcon from '@material-ui/icons/Info';

import withAuthorization from "../hocs/withAuthorization";
import LargePaper from "../components/common/LargePaper";
import {ALERT, PAGES, THEME, TIPS} from "../constants/routes";
import DashboardCard from "../components/common/DashboardCard";
import AuthService from "../services/AuthService";
import {ROLE_ADMIN} from "../constants/roles";

const iconStyle = {
  "marginTop": '0.9em',
  "width": '2em',
  "height": '2em'
};

const shortcuts = [
  {
    'label': 'Alertes',
    'link': ALERT,
    'icon': <NotificationImportantIcon style={iconStyle} />
  },
  {
    'label': 'Thèmes',
    'link': THEME,
    'icon': <ColorLensIcon style={iconStyle} />
  },
  {
    'label': 'Pages',
    'link': PAGES,
    'icon': <WebIcon style={iconStyle} />
  },
  {
    'label': 'Astuces',
    'link': TIPS,
    'icon': <InfoIcon style={iconStyle} />
  },
];

const cards = shortcuts.map(function (element) {
  return (
    <Grid item xs={12} md={12} lg={6} xl={4} key={element.label}>
      <DashboardCard
        icon={element.icon}
        label={element.label}
        link={element.link}
      />
    </Grid>
  )
});

export class HomePage extends Component {

  static propTypes = {
    // eslint-disable-next-line
    history: PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props) {
    if (!AuthService.isGranted(ROLE_ADMIN)) {
      props.history.push(ALERT);
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state={};
  }

  // eslint-disable-next-line
  render() {
    return (
      <LargePaper title="Back-office">
        <Grid container>
          {cards}
        </Grid>
      </LargePaper>
    );
  }
}

export default withAuthorization('Accueil')(HomePage);
