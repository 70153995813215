import * as types from './types';
import ApiService from '../../services/ApiService';
import {genericInfoAction, handleError} from "../infos/actions";

export const configsPendingAction = () => ({
  type: types.CONFIGS_PENDING,
});

export const configSendingAction = () => ({
  type: types.CONFIG_SENDING
});

export const configsDownloadedAction = (configs) => ({
  type: types.CONFIGS_DOWNLOADED,
  payload: configs,
});

export const configsFailedAction = (error) => ({
  type: types.CONFIGS_FAILED,
  payload: error,
});

export function getConfigurations(authUserToken) {
  return (dispatch) => {
    dispatch(configsPendingAction());
    return ApiService
      .getConfigurations(authUserToken)
      .then((data) => {
        dispatch(configsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(configsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putConfigurations(authUserToken, configs) {
  return (dispatch) => {
    dispatch(configSendingAction());
    return ApiService
      .putConfigurations(authUserToken, configs)
      .then((data) => {
        dispatch(configsDownloadedAction(data));
        dispatch(genericInfoAction('Configuartion sauvegardée'));
      })
      .catch((error) => {
        dispatch(configsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
