import React, {Component} from 'react';
import {compose} from "recompose";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {handleError} from "../../stores/infos/actions";
import LargePaper from "../../components/common/LargePaper";
import withAuthorization from "../../hocs/withAuthorization";
import Spinner from "../../components/common/Spinner";
import {PAGE_EDIT} from "../../constants/routes";
import CustomTable from "../../components/common/table/CustomTable";
import {getWebPages} from "../../stores/webPages/actions";

const INITIAL_STATE = {
  datas: []
};

export class WebPagesPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getWebPages: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (!props.webPages && !props.pending) {
      props.getWebPages(props.authAdmin.token);
    }
    if (props.webPages) {
      if (state.datas === INITIAL_STATE.datas) {
        newState = {...newState, datas: props.webPages};
      }
      if (props.webPages !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {...newState, datas: props.webPages}
      }
    }
    return newState;
  }

  handleEdit = element => {
    this.props.history.push(PAGE_EDIT.replace(':pageId', element.id));
  };

  render() {

    const columnData = [
      {id: 'translations[lang === \'fr\'].title', label: 'Titre', specialOrderBy: 'title',
        numeric: false, disablePadding: false},
      {id: 'tag', label: 'Tag', numeric: false, disablePadding: false},
      {id: 'imagePath', label: 'Image', numeric: false, image: true, disablePadding: false}
    ];
    return (
      <LargePaper title="Gestion des pages">
        <div>
          {this.props.pending &&
          <Spinner />
          }
          {!this.props.pending && (
            <CustomTable
              datas={this.state.datas}
              onEdit={this.handleEdit}
              paginationLabel='Page Web par page'
              emptyDataMessage='Aucune page Web'
              columnData={columnData}
              sending={this.props.sending}
              showDelete={false}
            />
          )}
        </div>
      </LargePaper>
    );
  }
}


function mapStateToProps(state) {
  return {
    authAdmin: state.admins.authAdmin,
    webPages: state.webPages.content,
    pending: state.webPages.pending,
    sending: state.webPages.sending,
    error: state.webPages.error,
  };
}

export default compose(
  withAuthorization('Pages'),
  connect(mapStateToProps, {getWebPages, handleError})
)(WebPagesPage);
