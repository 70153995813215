import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.THEMES_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.THEME_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.THEMES_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.THEME_DOWNLOADED: {
      const themeDownloaded = action.payload;
      let themes = [];
      let themeIndex = -1;
      if (state.content) {
        themes = state.content.slice();
        themeIndex = themes.findIndex(theme => theme.id === themeDownloaded.id);
      }
      if (themeIndex === -1) {
        themes.push(themeDownloaded);
      } else {
        themes[themeIndex] = themeDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: themes,
      };
    }
    case types.THEME_BLOC_DOWNLOADED: {
      const themeId = action.payload.themeId;
      const themeBlocDownloaded = action.payload.bloc;
      let themeForBloc;
      let themes = [];
      let themeIndex = -1;
      let themeBlocs = [];
      let themeBlocIndex = -1;
      if (state.content) {
        themes = state.content.slice();
        themeIndex = themes.findIndex(theme => theme.id === themeId);
        themeForBloc = themes[themeIndex];
        themeBlocs = themeForBloc.themeBlocs;
        themeBlocIndex = themeBlocs.findIndex(themeBloc => themeBloc.id === themeBlocDownloaded.id);
      }
      if (themeBlocIndex === -1) {
        themeForBloc.themeBlocs.push(themeBlocDownloaded);
      } else {
        themeForBloc.themeBlocs[themeBlocIndex] = themeBlocDownloaded;
      }

      themes[themeIndex] = themeForBloc;
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: themes,
      };
    }
    case types.THEME_REMOVED: {
      const theme = action.payload;
      let themes = state.content.slice();
      if (state.content !== null) {
        let isTheme = (themeId) => {
          return function (element) {
            return element.id !== themeId;
          }
        };
        themes = state.content.filter(isTheme(theme.id));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: themes,
      };
    }

    case types.THEME_BLOC_REMOVED: {
      const themeBloc = action.payload;
      let RemovedThemeOrder = themeBloc.order;
      let themes = state.content.slice();
      let themeIndex = themes.findIndex(theme => theme.themeBlocs.includes(themeBloc));
      if (state.content !== null && themeIndex !== -1) {
        let isThemeBloc = (themeBlocId) => {
          return function (element) {
            return element.id !== themeBlocId;
          }
        };
        let theme = themes[themeIndex];
        theme.themeBlocs = theme.themeBlocs.filter(isThemeBloc(themeBloc.id));
        theme.themeBlocs.forEach(function (element) {
          if (element.order > RemovedThemeOrder) {
            element.order = element.order - 1;
          }
        });
        themes[themeIndex] = theme;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: themes,
      };
    }
    case types.THEMES_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
