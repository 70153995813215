import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  refreshPending: false,
  sending: false,
  authAdmin: null,
  reconnection: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADMIN_LOGOUT:
      return INITIAL_STATE;
    case types.ADMINS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.ADMIN_REFRESH_PENDING:
      return {
        ...state,
        refreshPending: true,
      };
    case types.ADMIN_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.ADMINS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.ADMIN_DOWNLOADED: {
      const adminDownloaded = action.payload;
      let admins = state.content.slice();
      let adminIndex = -1;
      if (state.content) {
        admins = state.content.slice();
        adminIndex = admins.findIndex(admin => admin.id === adminDownloaded.id);
      }
      if (adminIndex === -1) {
        admins.push(action.payload);
      } else {
        admins[adminIndex] = action.payload;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: admins,
      };
    }
    case types.ADMIN_REMOVED: {
      const admin = action.payload;
      let admins = state.content.slice();
      if (state.content !== null) {
        let isAdmin = (adminId) => {
          return function (element) {
            return element.id !== adminId;
          }
        };
        admins = state.content.filter(isAdmin(admin.id));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: admins,
      };
    }
    case types.ADMIN_LOGIN:
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        authAdmin: action.payload,
      };
    case types.ADMIN_REFRESH : {
      let newAuth = {
        ...state.authAdmin,
        token: action.payload.token,
        refresh_token: action.payload.refresh_token
      };
      return {
        ...state,
        error: null,
        refreshPending: false,
        authAdmin: newAuth,
      };
    }
    // TODO change ADMIN_UPDATE ...
    case types.ADMIN_UPDATE:
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        authAdmin: action.payload,
      };
    case types.ADMINS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
