import {TIG_IDEA_GRAY, TIG_RASPBERRY, TIG_WHITE} from "./colors";

export const DEFAULT_MARGIN = '1vh';

const styles = {
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    textAlign: 'center'
  },
  contents: {
    display: 'contents'
  },
  smallLayout: {
    margin: '25vh 1.5em 1.5em 1.5em',
    overflow: 'hidden',
  },
  logo: {
    width: '100%',
    padding: '5% 20%',
  },
  logoSmall: {
    display: 'block',
    width: '70%',
    padding: '4px 16px',
    margin: 'auto'
  },
  defaultButton: {
    display: 'flex',
    width: '220px',
    fontWeight: 'bold',
    marginTop: DEFAULT_MARGIN,
    marginBottom: DEFAULT_MARGIN,
    padding: '4px 16px',
    flexDirection: 'row'
  },
  titleUnderline: {
    backgroundColor:TIG_RASPBERRY,
    height:1,
    width: '80%',
    display: 'inline-block',
  },
  media: {
    border: '1px dashed black',
    padding: '10px',
    marginLeft: '15px',
    marginRight: '15px',
    borderRadius: '10px'
  },
  fabProgress: {
    color: TIG_IDEA_GRAY,
    position: 'absolute',
    zIndex: 1,
  },
  addButton: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    bottom: '3vh',
    right: '3vh',
  },
  gridQuill: {
    backgroundColor: TIG_WHITE,
    width: '100%',
    margin: 'auto'
  },
  emptyDiv: {
    height:10,
  },
  /*
  fromGridContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    textAlign: 'center',
    padding: 10
  },
  */
  fromGridContainer: {
    marginBottom: 10,
    width: '100%'
  },
  background: {
    padding: '0',
    overflow: 'hidden',
    textAlign: 'center',
    width: '80vw',
    left: '10vw',
    height: '80vh',
    top: '10vh',
    position: "absolute",
    zIndex: 0
  },
  backgroundImage: {
    minHeight: '100%',
    minWidth: '100%',
    maxHeight: '150%',
    maxWidth: '150%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  backgroundLogo: {
    height: '92px',
    width: '92px',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: 25,
    left: 25,
  },
  smallLogoBlock: {
    maxHeight: '15em',
    maxWidth: '100%',
    overflow: 'hidden',
    textAlign: 'center'
  },
  smallLogo: {
    maxHeight: '15em',
  },
  smallRightlayout: {
    overflow: 'hidden',
    padding: '2em',
    textAlign: 'center',
    zIndex: 10,
    width: 'auto',
    right: '20vw',
    height: 'auto',
    top: '40vh',
    position: "absolute",
  },
  colorPicker: {
    width: '200px',
  }
};

export const quillModules = {
  toolbar: {
    container : [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike'],
      [{'list': 'ordered'}, {'list': 'bullet'},],
      ['link'],
      ['clean']
    ],
  },
  clipboard: {
    matchVisual: false
  }
};

export const quillFormats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

export default styles;
