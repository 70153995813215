import React from 'react';
import withAuthorization from "../hocs/withAuthorization";
import LargePaper from "../components/common/LargePaper";
import ConfigurationForm from "../components/forms/ConfigurationForm";

export const HomePage = () => {
  return (
    <LargePaper title="Configuration">
      <ConfigurationForm />
    </LargePaper>
  );
};

export default withAuthorization('Configuration')(HomePage);