import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ALERTS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.ALERTS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.ALERTS_FAILED: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    }
    case types.ALERT_SENDING: {
      return {
        ...state,
        sending: true,
      };
    }
    case types.ALERT_DOWNLOADED: {
      const alertDownloaded = action.payload;
      let alerts = [];
      let alertIndex = -1;
      if (state.content) {
        alerts = state.content.slice();
        alertIndex = alerts.findIndex(alert => alert.id === alertDownloaded.id);
      }
      if (alertIndex === -1) {
        alerts.push(alertDownloaded);
      } else {
        alerts[alertIndex] = alertDownloaded;
      }
      return {
        ...state,
        sending: false,
        pending: false,
        content: alerts
      };
    }
    case types.ALERT_REMOVED : {
      const alert = action.payload;
      let alerts = state.content.slice();
      if (state.content !== null) {
        let isAlert = (alertId) => {
          return function (element) {
            return element.id !== alertId;
          }
        };
        alerts = state.content.filter(isAlert(alert.id));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: alerts,
      };
    }
    default:
      break;
  }
  return state;
}
