import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.WEB_PAGES_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.WEB_PAGE_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.WEB_PAGES_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.WEB_PAGE_DOWNLOADED: {
      const webPageDownloaded = action.payload;
      let webPages = [];
      let webPageIndex = -1;
      if (state.content) {
        webPages = state.content.slice();
        webPageIndex = webPages.findIndex(
          webPage => webPage.id === webPageDownloaded.id
        );
      }
      if (webPageIndex === -1) {
        webPages.push(webPageDownloaded);
      } else {
        webPages[webPageIndex] = webPageDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: webPages,
      };
    }
    case types.WEB_PAGE_BLOC_DOWNLOADED: {
      const webPageId = action.payload.webPageId;
      const webPageBlocDownloaded = action.payload.bloc;
      let webPageForBloc;
      let webPages = [];
      let webPageIndex = -1;
      let webPageBlocs = [];
      let webPageBlocIndex = -1;
      if (state.content) {
        webPages = state.content.slice();
        webPageIndex = webPages.findIndex(webPage => webPage.id === webPageId);
        webPageForBloc = webPages[webPageIndex];
        webPageBlocs = webPageForBloc.webPageBlocs;
        webPageBlocIndex = webPageBlocs.findIndex(
          webPageBloc => webPageBloc.id === webPageBlocDownloaded.id
        );
      }
      if (webPageBlocIndex === -1) {
        webPageForBloc.webPageBlocs.push(webPageBlocDownloaded);
      } else {
        webPageForBloc.webPageBlocs[webPageBlocIndex] = webPageBlocDownloaded;
      }

      webPages[webPageIndex] = webPageForBloc;
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: webPages,
      };
    }
    case types.WEB_PAGE_REMOVED: {
      const webPage = action.payload;
      let webPages = state.content.slice();
      if (state.content !== null) {
        let isTheme = (webPageId) => {
          return function (element) {
            return element.id !== webPageId;
          }
        };
        webPages = state.content.filter(isTheme(webPage.id));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: webPages,
      };
    }

    case types.WEB_PAGE_BLOC_REMOVED: {
      const webPageBloc = action.payload;
      let RemovedWebPageBlocOrder = webPageBloc.order;
      let webPages = state.content.slice();
      let webPageIndex = webPages.findIndex(webPage => webPage.webPageBlocs.includes(webPageBloc));
      if (state.content !== null && webPageIndex !== -1) {
        let isThemeBloc = (webPageBlocId) => {
          return function (element) {
            return element.id !== webPageBlocId;
          }
        };
        let webPage = webPages[webPageIndex];
        webPage.webPageBlocs = webPage.webPageBlocs.filter(isThemeBloc(webPageBloc.id));
        webPage.webPageBlocs.forEach(function (element) {
          if (element.order > RemovedWebPageBlocOrder) {
            element.order = element.order - 1;
          }
        });
        webPages[webPageIndex] = webPage;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: webPages,
      };
    }
    case types.WEB_PAGES_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
