import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const webPagesPendingAction = () => ({
  type: types.WEB_PAGES_PENDING,
});
export const webPageSendingAction = () => ({
  type: types.WEB_PAGE_SENDING,
});

export const webPagesDownloadedAction = webPages => ({
  type: types.WEB_PAGES_DOWNLOADED,
  payload: webPages,
});

export const webPageDownloadedAction = (webPage) => ({
  type: types.WEB_PAGE_DOWNLOADED,
  payload: webPage
});

export const webPageBlocDownloadedAction = (webPageId, webPageBloc) => ({
  type: types.WEB_PAGE_BLOC_DOWNLOADED,
  payload: {
    webPageId: webPageId,
    bloc: webPageBloc
  }
});

export const webPageRemovedAction = (webPage) => ({
  type: types.WEB_PAGE_REMOVED,
  payload: webPage,
});

export const webPageBlocRemovedAction = (webPageBloc) => ({
  type: types.WEB_PAGE_BLOC_REMOVED,
  payload: webPageBloc,
});

export const webPagesFailedAction = error => ({
  type: types.WEB_PAGES_FAILED,
  payload: error,
});


// --------------------------------
// Actions

// Récupèration de la liste des webPages
export function getWebPages(token) {
  return (dispatch) => {
    dispatch(webPagesPendingAction());
    return ApiService
      .getWebPages(token)
      .then((data) => {
        dispatch(webPagesDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(webPagesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'un bloc
export function postWebPageBloc(token, webPageId, webPageBloc) {
  return (dispatch) => {
    dispatch(webPageSendingAction());

    return ApiService
      .postWebPageBloc(token, webPageId, webPageBloc)
      .then((data) => {
        dispatch(webPageBlocDownloadedAction(webPageId, data));
        return data.id
      })
      .catch((error) => {
        dispatch(webPagesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'une image pour une page
export function postWebPagePicture(
  token,
  webPageId,
  picture,
  onUploadProgress = null
) {
  return (dispatch) => {
    dispatch(webPageSendingAction());
    return ApiService
      .postWebPagePicture(token, webPageId, picture, onUploadProgress)
      .then((result) => {
        dispatch(webPageDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(webPagesFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

// Ajout d'une image pour un bloc
export function postWebPageBlocPicture(
  token,
  webPageId,
  webPageBlocId,
  lang,
  picture,
  onUploadProgress = null
) {
  return (dispatch) => {
    dispatch(webPageSendingAction());
    return ApiService
      .postWebPageBlocPicture(token, webPageId, webPageBlocId, lang, picture, onUploadProgress)
      .then((result) => {
        dispatch(webPageBlocDownloadedAction(webPageId, result));
        return result;
      })
      .catch((error) => {
        dispatch(webPagesFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

// Edition d'un webPage
export function putWebPage(token, webPageId, webPage) {
  return (dispatch) => {
    dispatch(webPageSendingAction());

    return ApiService
      .putWebPage(token, webPageId, webPage)
      .then((data) => {
        const updatedWebPage = {
          ...data,
          id: webPageId,
        };
        dispatch(webPageDownloadedAction(updatedWebPage));
        return data.id
      })
      .catch((error) => {
        dispatch(webPagesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Edition d'un webPage
export function putWebPageBloc(token, webPageId, webPageBlocId, webPageBloc) {
  return (dispatch) => {
    dispatch(webPageSendingAction());

    return ApiService
      .putWebPageBloc(token, webPageId, webPageBlocId, webPageBloc)
      .then((data) => {
        const updatedWebPageBloc = {
          ...data,
          id: webPageBlocId,
        };
        dispatch(webPageBlocDownloadedAction(webPageId, updatedWebPageBloc));
        return data.id
      })
      .catch((error) => {
        dispatch(webPagesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Suppression d'un webPageBloc
export function deleteWebPageBloc(token, webPageId, webPageBloc) {
  return (dispatch) => {
    dispatch(webPagesPendingAction());
    return ApiService
      .deleteWebPageBloc(token, webPageId, webPageBloc.id)
      .then(() => {
        dispatch(webPageBlocRemovedAction(webPageBloc));
      })
      .catch((error) => {
        dispatch(webPagesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
