import API_ROOT from './api';

export const API_LOGIN                = API_ROOT + '/admin/login';
export const API_REFRESH              = API_ROOT + '/admin/refresh';
export const API_CONFIGURATIONS       = API_ROOT + '/admin/configurations';
export const API_ADMINS               = API_ROOT + '/admin/admins';
export const API_ADMINS_ME            = API_ROOT + '/admin/admins/me';
export const API_ADMINS_ID            = API_ROOT + '/admin/admins/:adminId';
export const API_PASSWORD_LOST        = API_ROOT + '/public/password/lost';
export const API_PASSWORD_CHANGE      = API_ROOT + '/public/password/change';
export const API_TIPS                 = API_ROOT + '/admin/tips';
export const API_TIPS_ID_IMAGE        = API_ROOT + '/admin/tips/:tipId/image';
export const API_TIPS_ID              = API_ROOT + '/admin/tips/:tipId';
export const API_THEMES               = API_ROOT + '/admin/themes';
export const API_THEMES_ID            = API_ROOT + '/admin/themes/:themeId';
export const API_THEMES_BLOC          = API_ROOT + '/admin/themes/:themeId/bloc';
export const API_THEMES_BLOC_ID       = API_ROOT + '/admin/themes/:themeId/bloc/:themeBlocId';
export const API_THEMES_BLOC_ID_IMAGE = API_ROOT + '/admin/themes/:themeId/bloc/:themeBlocId/image';
export const API_WEB_PAGES            = API_ROOT + '/admin/pages';
export const API_WEB_PAGES_ID         = API_ROOT + '/admin/pages/:pageId';
export const API_WEB_PAGES_ID_IMAGE   = API_ROOT + '/admin/pages/:pageId/image';
export const API_WEB_PAGES_BLOC       = API_ROOT + '/admin/pages/:pageId/bloc';
export const API_WEB_PAGES_BLOC_ID    = API_ROOT + '/admin/pages/:pageId/bloc/:pageBlocId';
export const API_WEB_PAGES_BLOC_ID_IMAGE = API_ROOT
  + '/admin/pages/:pageId/bloc/:pageBlocId/image/:lang';
export const API_ALERTS               = API_ROOT + '/admin/alerts';
export const API_ALERT                = API_ROOT + '/admin/alerts/:alertId';
export const API_ALERT_CATEGORIES     = API_ROOT + '/admin/categories';
export const API_ALERT_PRESETS        = API_ROOT + '/admin/presets';
