import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const tipsPendingAction = () => ({
  type: types.TIPS_PENDING,
});
export const tipSendingAction = () => ({
  type: types.TIP_SENDING,
});

export const tipsDownloadedAction = tips => ({
  type: types.TIPS_DOWNLOADED,
  payload: tips,
});

export const tipDownloadedAction = (tip) => ({
  type: types.TIP_DOWNLOADED,
  payload: tip
});

export const tipRemovedAction = (tip) => ({
  type: types.TIP_REMOVED,
  payload: tip,
});

export const tipsFailedAction = error => ({
  type: types.TIPS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

// Récupèration de la liste des astuces
export function getTips(token) {
  return (dispatch) => {
    dispatch(tipsPendingAction());
    return ApiService
      .getTips(token)
      .then((data) => {
        dispatch(tipsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tipsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'une astuce
export function postTip(token, tip) {
  return (dispatch) => {
    dispatch(tipSendingAction());

    return ApiService
      .postTip(token, tip)
      .then((data) => {
        dispatch(tipDownloadedAction(data));
        return data.id
      })
      .catch((error) => {
        dispatch(tipsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'un image
export function postTipPicture(token, tipId, picture, onUploadProgress = null) {
  return (dispatch) => {
    dispatch(tipSendingAction());
    return ApiService
      .postTipPicture(token, tipId, picture, onUploadProgress)
      .then((result) => {
        dispatch(tipDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(tipsFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

// Edition d'une astuce
export function putTip(token, tipId, tip) {
  return (dispatch) => {
    dispatch(tipSendingAction());
    return ApiService
      .putTip(token, tipId, tip)
      .then((data) => {
        const updatedTip = {
          ...data,
          id: tipId,
        };
        dispatch(tipDownloadedAction(updatedTip));
        return data.id
      })
      .catch((error) => {
        dispatch(tipsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Suppression d'un admin
export function deleteTip(token, tip) {
  return (dispatch) => {
    dispatch(tipSendingAction());
    return ApiService
      .deleteTip(token, tip.id)
      .then(() => {
        dispatch(tipRemovedAction(tip));
      })
      .catch((error) => {
        dispatch(tipsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
