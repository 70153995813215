import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CONFIGS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.CONFIG_SENDING:
      return {
        ...state,
        sending: true
      };
    case types.CONFIGS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.CONFIGS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
