import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const themesPendingAction = () => ({
  type: types.THEMES_PENDING,
});
export const themeSendingAction = () => ({
  type: types.THEME_SENDING,
});

export const themesDownloadedAction = themes => ({
  type: types.THEMES_DOWNLOADED,
  payload: themes,
});

export const themeDownloadedAction = (theme) => ({
  type: types.THEME_DOWNLOADED,
  payload: theme
});

export const themeBlocDownloadedAction = (themeId, themeBloc) => ({
  type: types.THEME_BLOC_DOWNLOADED,
  payload: {
    themeId: themeId,
    bloc: themeBloc
  }
});

export const themeRemovedAction = (theme) => ({
  type: types.THEME_REMOVED,
  payload: theme,
});

export const themeBlocRemovedAction = (themeBloc) => ({
  type: types.THEME_BLOC_REMOVED,
  payload: themeBloc,
});

export const themesFailedAction = error => ({
  type: types.THEMES_FAILED,
  payload: error,
});


// --------------------------------
// Actions

// Récupèration de la liste des themes
export function getThemes(token) {
  return (dispatch) => {
    dispatch(themesPendingAction());
    return ApiService
      .getThemes(token)
      .then((data) => {
        dispatch(themesDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(themesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'un theme
export function postTheme(token, theme) {
  return (dispatch) => {
    dispatch(themeSendingAction());

    return ApiService
      .postTheme(token, theme)
      .then((data) => {
        dispatch(themeDownloadedAction(data));
        return data.id
      })
      .catch((error) => {
        dispatch(themesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'un bloc
export function postThemeBloc(token, themeId, themeBloc) {
  return (dispatch) => {
    dispatch(themeSendingAction());

    return ApiService
      .postThemeBloc(token, themeId, themeBloc)
      .then((data) => {
        dispatch(themeBlocDownloadedAction(themeId, data));
        return data.id
      })
      .catch((error) => {
        dispatch(themesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'une image pour un bloc
export function postThemeBlocPicture(
  token,
  themeId,
  themeBlocId,
  picture,
  onUploadProgress = null
) {
  return (dispatch) => {
    dispatch(themeSendingAction());
    return ApiService
      .postThemeBlocPicture(token, themeId, themeBlocId, picture, onUploadProgress)
      .then((result) => {
        dispatch(themeBlocDownloadedAction(themeId, result));
        return result;
      })
      .catch((error) => {
        dispatch(themesFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

// Edition d'un theme
export function putTheme(token, themeId, theme) {
  return (dispatch) => {
    dispatch(themeSendingAction());

    return ApiService
      .putTheme(token, themeId, theme)
      .then((data) => {
        const updatedTheme = {
          ...data,
          id: themeId,
        };
        dispatch(themeDownloadedAction(updatedTheme));
        return data.id
      })
      .catch((error) => {
        dispatch(themesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Edition d'un theme
export function putThemeBloc(token, themeId, themeBlocId, themeBloc) {
  return (dispatch) => {
    dispatch(themeSendingAction());

    return ApiService
      .putThemeBloc(token, themeId, themeBlocId, themeBloc)
      .then((data) => {
        const updatedThemeBloc = {
          ...data,
          id: themeBlocId,
        };
        dispatch(themeBlocDownloadedAction(themeId, updatedThemeBloc));
        return data.id
      })
      .catch((error) => {
        dispatch(themesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Suppression d'un theme
export function deleteTheme(token, theme) {
  return (dispatch) => {
    dispatch(themeSendingAction());
    return ApiService
      .deleteTheme(token, theme.id)
      .then(() => {
        dispatch(themeRemovedAction(theme));
      })
      .catch((error) => {
        dispatch(themesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Suppression d'un themeBloc
export function deleteThemeBloc(token, themeId, themeBloc) {
  return (dispatch) => {
    dispatch(themeSendingAction());
    return ApiService
      .deleteThemeBloc(token, themeId, themeBloc.id)
      .then(() => {
        dispatch(themeBlocRemovedAction(themeBloc));
      })
      .catch((error) => {
        dispatch(themesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
