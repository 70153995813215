import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import AdminForm from "../../components/forms/AdminForm";
import LargePaper from "../../components/common/LargePaper";

export const AdminAddPage = props => {
  return (
    <LargePaper title="Ajouter un administrateurs">
      <AdminForm history={props.history} />
    </LargePaper>
  );
};

AdminAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withAuthorization('Administrateurs')(AdminAddPage);
