import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from "recompose";
import {connect} from "react-redux";
import * as ReactDOM from "react-dom";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles/index";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider/Divider";
import Typography from "@material-ui/core/Typography/Typography";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import stylesDefault, {quillFormats, quillModules} from "../../../constants/stylesDefault";
import CustomFormContainer from "./CustomFormContainer";
import CustomTextField from "./CustomTextField";
import CustomPasswordField from "./CustomPasswordField";
import CustomSelectField from "./CustomSelectField";
import CustomPictureDropzone from "./CustomPictureDropzone";
import CustomCheckboxField from "./CustomCheckboxField";
import {handleError} from "../../../stores/infos/actions";
import {TIG_RASPBERRY} from "../../../constants/colors";

/**
 * Style of Form elements
 *
 * @returns json
 */
export const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  divider: {
    backgroundColor: theme.palette.primary.main
  },
  title: {
    float: 'left',
    marginTop: '1em',
    fontWeight: 'bold'
  },
  noPadding: {
    paddingBottom: '0!important',
    paddingTop: '0!important'
  },
  richTextLabel: {
    color: TIG_RASPBERRY,
    fontWeight: 'bold',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1,
    float: 'left',
    margin: '8px',
    paddingTop: '8px'
  }
});

class CustomForm extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    formFields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        picture: PropTypes.string, // Only mandatory for pictureDropzone.
        type: PropTypes.string, //TODO : Only in define array [textField, richText, pictureDropzone, checkboxField, selectField, passwordField]
        subtype: PropTypes.string, //TODO : Only in define array [time, date]
        condition: PropTypes.string,
        disabled: PropTypes.bool,
        gridMD: PropTypes.number,
        label: PropTypes.string,
        options: PropTypes.array,
        isMandatory: PropTypes.bool,
        errorRegex: PropTypes.object, // TODO : Make it more genec to use in other component than CustomPasswordField
        onChange: PropTypes.func,
        noPadding: PropTypes.bool,
      })
    ).isRequired,
    onSubmit: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string,
    handleError: PropTypes.func.isRequired,
    typeForm: PropTypes.number,
    sending: PropTypes.bool
  };

  static defaultProps = {
    sending: false,
    typeForm: 0,
    buttonLabel: 'OK'
  };
  // ----------------
  // Constructor

  constructor(props, context) {
    super(props, context);
    this.state = {...props.data};
  }

  // ----------------
  // Computing

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};
    if ( props.typeForm !== 0
      && JSON.stringify(props.data) !== JSON.stringify(state) && props.onUpdate) {
      props.onUpdate(newState);
    }
    return null;
  }

  isValidFormFields = formFields => {
    let isValid = true;
    formFields.forEach(field => {
        if (field.isMandatory) {
          isValid = isValid && this.state[field.id] !== null && this.state[field.id] !== '';
          if (field.errorRegex) {
            isValid = isValid && this.state[field.id].match(field.errorRegex);
          }
        }
    });
    return isValid;
  };

  handleMediaDrop = (accepted, rejected, path, picture) => {
    if (rejected.length) {
      this.props.handleError({
        message: 'Le fichier attendu doit être une image, le fichier envoyé est de type '
          + rejected[0].type
      })
    } else {
      this.setState({[path]: URL.createObjectURL(accepted[0]), [picture]: accepted[0]});
    }
  };

  displayFormField = (field, classes) => {
    if (typeof field.id === 'undefined') {
      return;
    }

    if (field.condition) { //TODO : make more generic in order to handle other kind of conditions
      const conditionArray = field.condition.split('===');
      if (conditionArray.length === 2 && this.state[conditionArray[0]] !== conditionArray[1]) {
        return;
      } else if (conditionArray.length === 1) {
        if (conditionArray[0].includes('!')) {
          if (this.state[conditionArray[0].replace('!', '')]) {
            return;
          }
        } else if (!this.state[conditionArray[0]]) {
          return;
        }
      }
    }

    let gridMD = field.gridMD || 12;

    if (field.type) {
      if (field.type === 'divider') {
        return (
          <Grid
            item
            xs={12}
            sm={12}
            md={gridMD || 12}
            className={field.noPadding && classes.noPadding}
            key={field.id}
          >
            <Divider id={field.id} variant="middle" className={classes.divider} />
          </Grid>
        );
      } else if (field.type === 'title') {
        return (
          <Grid
            item
            xs={12}
            sm={12}
            md={gridMD || 12}
            className={field.noPadding && classes.noPadding}
            key={field.id}
          >
            <Typography
              variant={field.variant || 'h6'}
              id={field.id}
              className={classes.title}
            >
              {field.label.toUpperCase()}
            </Typography>
          </Grid>
        );
      } else if (field.type === 'textField') {
        return (
          <CustomTextField
            gridMD={gridMD}
            key={field.id}
            id={field.id}
            type={field.subtype || 'text'}
            label={field.label}
            onChange={event => {
              field.onChange && field.onChange({...this.state, [field.id]: event.target.value});
              this.setState({[field.id]: event.target.value});
            }}
            value={this.state[field.id]}
            disabled={field.disabled}
            isMandatory={field.isMandatory}
          />
        )
      } else if (field.type === 'passwordField') {
        return (
          <CustomPasswordField
            className={classes.input}
            gridMD={gridMD}
            key={field.id}
            id={field.id}
            label={field.label}
            onChange={event => this.setState({[field.id]: event.target.value})}
            value={this.state[field.id]}
            isMandatory={field.isMandatory}
            error={this.state[field.id] !== null &&
            this.state[field.id].match(field.errorRegex) === null}
          />
        )
      } else if (field.type === 'selectField') {
        return (
          <CustomSelectField
            gridMD={gridMD}
            key={field.id}
            id={field.id}
            label={field.label}
            onChange={event => this.setState({[field.id]: event.target.value})}
            value={this.state[field.id]}
            isMandatory={field.isMandatory}
          >
            {
              Object.keys(field.options).map(
                function (value) {
                  return (
                    <MenuItem key={value} value={value}>
                      {field.options[value]}
                    </MenuItem>
                  )
                }
              )
            }
          </CustomSelectField>
        )
      } else if (field.type === 'pictureDropzone') {
        return (
          <CustomPictureDropzone
            gridMD={gridMD}
            key={field.id}
            imagePath={this.state[field.id]}
            onDrop={(accepted, rejected) =>
              this.handleMediaDrop(accepted, rejected, field.id, field.picture)
            }
          />
        );
      } else if (field.type === 'checkboxField') {
        return (
          <CustomCheckboxField
            gridMD={gridMD}
            key={field.id}
            disabled={field.disabled}
            onChange={event => this.setState({[field.id]: event.target.checked})}
            value={this.state[field.id]}
            label={field.label}
          />
        );
      } else if (field.type === 'richText') {
        return (
          <Grid
            item
            key={field.id}
            xs={12}
            sm={12}
            md={gridMD}
            style={stylesDefault.gridQuill}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label htmlFor={field.id} className={classes.richTextLabel}>
              {field.label}
            </label>
            <ReactQuill
              id={field.id}
              modules={quillModules}
              formats={quillFormats}
              value={this.state[field.id]}
              onChange={value => {
                field.onChange && field.onChange({...this.state, [field.id]: value});
                this.setState({[field.id]: value});
              }}
            />
          </Grid>
        );
      } else if (field.type === 'input') {
        return (
          <Grid
            item
            key={field.id}
            xs={12}
            sm={12}
            md={gridMD}
          >
            <FormControl variant="outlined">
              <InputLabel
                ref={ref => {
                  if (!this.state.ref || !this.state.ref[field.label]) {
                    this.setState({
                      ref: {
                        ...this.state.ref,
                        // eslint-disable-next-line react/no-find-dom-node
                        [field.label]: ReactDOM.findDOMNode(ref)
                      }
                    });
                  }
                }}
                htmlFor={field.id}
              >
                {field.label}
              </InputLabel>
              <OutlinedInput
                type="color"
                id={field.id}
                labelWidth={this.state.ref && this.state.ref[field.label] ?
                  this.state.ref[field.label].offsetWidth : 0}
                style={stylesDefault.colorPicker}
                onChange={event => this.setState({[field.id]: event.target.value})}
                value={this.state[field.id]}
              />
            </FormControl>
          </Grid>
        );
      } else if (field.type === 'subform') {

        let subformData = null;
        let arrayToFilter;
        if (field.id.includes('[')) {
          let openBracketIndex = field.id.indexOf('[');
          let closeBracketIndex = field.id.indexOf(']');
          arrayToFilter = field.id.slice(0, openBracketIndex);
          let filterCondition = 'elem => elem.'
            .concat(field.id.slice(openBracketIndex + 1, closeBracketIndex));
          subformData = this.state[arrayToFilter].find(eval(filterCondition));
        }

        if (subformData) {

          let onSubformUpdate = (state) => {
            let indexOfSubformData = this.state[arrayToFilter].indexOf(subformData);
            this.setState({
              [arrayToFilter]: [
                ...this.state[arrayToFilter].slice(0, indexOfSubformData),
                state,
                ...this.state[arrayToFilter].slice(indexOfSubformData + 1)
              ]
            });
          };

          return (
            <CustomForm
              key={field.id}
              typeForm={1}
              data={subformData}
              formFields={field.options}
              onUpdate={state => onSubformUpdate(state)}
              sending={this.props.sending}
              classes={this.props.classes}
              handleError={this.props.handleError}
              onSubmit={this.props.onSubmit}
            />
          );
        }
      }
    }
  };

  // Render

  render() {

    const {
      classes,
      formFields,
      onSubmit,
      buttonLabel,
      sending
    } = this.props;


    const isValid = this.isValidFormFields(formFields) && !sending;

    const subFormRender = (
      <Grid container style={stylesDefault.fromGridContainer} spacing={16}>
        {formFields.map(field => (
          this.displayFormField(field, classes)
        ))}
      </Grid>
    );
    const formRender = (
      <CustomFormContainer onSubmit={event => onSubmit(event, {...this.state, ref: null})}>
        {subFormRender}
        <Button
          variant="contained"
          color="primary"
          disabled={!isValid}
          type="submit"
        >
          {buttonLabel}
        </Button>
      </CustomFormContainer>
    );


    if (this.props.typeForm === 0) {
      return formRender;
    } else {
      return subFormRender;
    }
  }
}

export default compose(
  withStyles(styles),
  connect(null, {handleError})
)(CustomForm);
