import React from 'react';
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {compose} from 'recompose';

import * as routes from '../constants/routes';
import {refresh, update} from '../stores/admins/actions';
import AuthService from '../services/AuthService';
import withMenu from "./withMenu";

// Intial State of the Component
const INITIAL_STATE = {
  email: '',
  password: '',
  showPassword: false,
};

const withAuthorization = title => (Component) => {
  class withAuthorization extends React.Component {

    // ----------------
    // Type of each Props

    static propTypes = {
      history: PropTypes.shape({
        push: PropTypes.func,
      }).isRequired,
      authAdmin: PropTypes.shape({
        id: PropTypes.string
      }),
      pending: PropTypes.bool,
      update: PropTypes.func.isRequired
    };


    // ----------------
    // Default Values

    static defaultProps = {
      authAdmin: null,
      pending: false
    };


    // ----------------
    // Life Cycle

    static getDerivedStateFromProps(props) {
      let newState = null;
      if (AuthService.isTokenExpired() && !props.refreshPending) {
        props.refresh(localStorage.getItem('refresh_token'));
      } else if (props.authAdmin === null) {
        if (AuthService.isAuthenticated()) {
          props.update(AuthService.getSession());
        } else {
          props.history.push(routes.SIGN_IN);
        }
      }
      return newState;
    }


    // ----------------
    // Constructor

    constructor(props) {
      super(props);
      this.state = {...INITIAL_STATE};
    }


    // ----------------
    // Rendering

    render() {
      if (this.props.authAdmin && !this.props.pending) {
        return (<Component {...this.props} />);
      }
      return null;
    }
  }

  const mapStateToProps = state => ({
    authAdmin: state.admins.authAdmin,
    pending: state.admins.pending,
    refreshPending: state.admins.refreshPending
  });

  return compose(
    withRouter,
    withMenu(title),
    connect(mapStateToProps, {update, refresh}),
  )(withAuthorization);
};

export default withAuthorization;
