import React from 'react';
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import queryString from 'query-string';
import {withStyles} from "@material-ui/core";
import Divider from "@material-ui/core/Divider/Divider";
import Typography from "@material-ui/core/Typography/Typography";
import logo from '../../Resources/images/logo_tignes.png';
import {notificationStyles} from "../../constants/stylesIphone";

export const IphoneNotificationPage = (props) => {
  let {classes} = props;
  let queries = queryString.parse(props.location.search);
  let text = (queries.notification) ? queries.notification : 'Notification';
  let date = new Date();

  let options = {weekday: 'long', month: 'long', day: 'numeric'};
  return (
    <div className={classes.root}>
      <div className={classes.center}>
        <Typography component="h2" variant="h2" className={classes.white}>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          {date.getUTCHours()}:{date.getUTCMinutes()<10 ? '0' : ''}{date.getUTCMinutes()}
        </Typography>
        <Typography variant="subtitle1" gutterBottom className={classes.white}>
          {date.toLocaleDateString('fr-FR', options)}
        </Typography>
      </div>
      <div className={classes.notification}>
        <div className={classes.notificationPart}>
          <div className={classes.notificationHeaderPart}>
            <img alt="logo" src={logo} className={classes.logo} />
          </div>
          <div className={classes.notificationHeaderPart}>
            <Typography variant="subtitle1" gutterBottom>
              Tignes
            </Typography>
          </div>
        </div>
        <Divider />
        <div className={classes.notificationPart}>
          <Typography variant="subtitle2" gutterBottom>
            {date.toLocaleString(
              'fr-FR',
              {
                timeZone: 'UTC',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              }
            )}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {" "}
            {text}
          </Typography>
        </div>
      </div>
    </div>
  );
};

IphoneNotificationPage.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};
export default compose(withRouter, withStyles(notificationStyles))(IphoneNotificationPage);