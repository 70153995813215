import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

import infosReducer from './infos/reducers';
import adminsReducer from './admins/reducers';
import configurationsReducer from './configurations/reducers';
import tipsReducer from './tips/reducers';
import themesReducer from './themes/reducers';
import webPagesReducer from './webPages/reducers';
import alertsReducer from './alerts/reducers';
import alertPresetsReducer from './alertPresets/reducers';
import alertCategoriesReducer from './alertCategories/reducers';

const reducers = combineReducers({
  infos: infosReducer,
  admins: adminsReducer,
  configurations: configurationsReducer,
  tips: tipsReducer,
  themes: themesReducer,
  webPages: webPagesReducer,
  alerts: alertsReducer,
  alertPresets: alertPresetsReducer,
  alertCategories: alertCategoriesReducer
});

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
