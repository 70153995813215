import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";
import {TIG_RASPBERRY_DARK} from "../../../constants/colors";

const styles = () => ({
  indicator: {
    color: TIG_RASPBERRY_DARK,
    position: 'relative',
    width: '100%',
    textAlign: 'left'
  }
});

const MandatoryFieldIndicator = (props) => {
  const text = "* champs obligatoires.";

  return (
    <Typography
      variant="subtitle1"
      gutterBottom
      className={props.classes.indicator}
    >
      {text}
    </Typography>);
};

MandatoryFieldIndicator.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MandatoryFieldIndicator);