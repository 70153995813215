import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";
import {getAdmins, deleteAdmin} from "../../stores/admins/actions";
import {handleError} from "../../stores/infos/actions";
import LargePaper from "../../components/common/LargePaper";
import withAuthorization from "../../hocs/withAuthorization";
import Spinner from "../../components/common/Spinner";
import {ADMIN_ADD, ADMIN_EDIT} from "../../constants/routes";
import CustomTable from "../../components/common/table/CustomTable";
import AuthService from "../../services/AuthService";
import {ROLE_SUPER_ADMIN} from "../../constants/roles";

const INITIAL_STATE = {
  datas: []
};

export class AdminPage extends React.Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getAdmins: PropTypes.func.isRequired,
    deleteAdmin: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authAdmin: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (!props.admins && !props.pending) {
      props.getAdmins(props.authAdmin.token);
    }
    if (props.admins) {
      if (state.datas === INITIAL_STATE.datas) {
        newState = {...newState, datas: props.admins};
      }
      if (props.admins !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {...newState, datas: props.admins}
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(ADMIN_ADD);
  };

  handleEdit = element => {
    this.props.history.push(ADMIN_EDIT.replace(':adminId', element.id));
  };

  handleDelete = element => {
    this.props.deleteAdmin(this.props.authAdmin.token, element)
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const columnData = [
      {id: 'email', label: 'Email', numeric: false, disablePadding: false},
      {id: 'firstname', label: 'Prénom', numeric: false, disablePadding: false},
      {id: 'lastname', label: 'Nom', numeric: false, disablePadding: false},
      {id: 'role', label: 'Rôle', numeric: false, disablePadding: false}
    ];
    return (
      <LargePaper title="Gestion des administrateurs">
        <div>
          {this.props.pending &&
            <Spinner />
          }
          {!this.props.pending && (
            <CustomTable
              datas={this.state.datas}
              onAdd={this.handleAdd}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              deleteExceptId={this.props.authAdmin && this.props.authAdmin.id}
              buttonLabel='Ajouter un administrateur'
              paginationLabel='Administrateurs par page'
              emptyDataMessage='Aucun administrateur'
              deleteDialogText='Êtes-vous sûr de vouloir supprimer cet administrateur ?'
              columnData={columnData}
              sending={this.props.sending}
              showDelete={AuthService.isGranted(ROLE_SUPER_ADMIN)}
            />
          )}
        </div>
      </LargePaper>
    );
  }
}


function mapStateToProps(state) {
  return {
    authAdmin: state.admins.authAdmin,
    admins: state.admins.content,
    pending: state.admins.pending,
    sending: state.admins.sending,
    error: state.admins.error,
  };
}

export default compose(
  withAuthorization('Administrateurs'),
  connect(mapStateToProps, {getAdmins, deleteAdmin, handleError})
)(AdminPage);
