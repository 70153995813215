import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import AdminForm from "../../components/forms/AdminForm";
import LargePaper from "../../components/common/LargePaper";

export const AdminAddPage = props => {
  return (
    <LargePaper title="Éditer un administrateur">
      <AdminForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

AdminAddPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Administrateurs')(AdminAddPage);
