import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import TipForm from "../../components/forms/TipForm";
import LargePaper from "../../components/common/LargePaper";

export const TipEditPage = props => {
  return (
    <LargePaper title="Éditer une astuce">
      <TipForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

TipEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Astuces')(TipEditPage);
