import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import Grid from "@material-ui/core/Grid";
import 'react-quill/dist/quill.snow.css';
import Typography from "@material-ui/core/Typography";

import {
  putWebPage,
  getWebPages,
  deleteWebPageBloc,
  putWebPageBloc, postWebPagePicture
} from "../../stores/webPages/actions";
import {handleError} from "../../stores/infos/actions";
import {PAGE_BLOC_ADD, PAGE_BLOC_EDIT, NOT_FOUND} from "../../constants/routes";
import Spinner from "../common/Spinner";

import AuthService from "../../services/AuthService";
import {ROLE_ADMIN} from "../../constants/roles";
import CustomTable from "../common/table/CustomTable";
import CustomForm from "../common/form/CustomForm";

// Intial State of the Component
const INITIAL_STATE = {
  id: '',
  tag: '',
  imagePath: '',
  title_fr: '',
  title_en: '',
  webPageBlocs: [],
  pictureToUpload: null,
  sending: false
};

const stateWithWebPage = webPage => ({
  id: webPage.id,
  tag: webPage.tag,
  imagePath: webPage.imagePath,
  title_fr: webPage.translations.find(translation => translation.lang === 'fr').title,
  title_en: webPage.translations.find(translation => translation.lang === 'en').title,
  webPageBlocs: webPage.webPageBlocs
});

export class WebPageForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    putWebPage: PropTypes.func.isRequired,
    putWebPageBloc: PropTypes.func.isRequired,
    deleteWebPageBloc: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.webPages && !props.pending
      && props.token && props.match && props.match.params.pageId) {
      props.getWebPages(props.token);
    }
    if (props.webPages && props.match && props.match.params.pageId) {
      const webPages = props.webPages;
      const currentWebPageId = props.match.params.pageId;
      const foundWebPage = webPages.find(webPage => webPage.id === currentWebPageId);
      if (!foundWebPage) {
        props.history.push(NOT_FOUND);
        return null;
      }


      if (state.id === INITIAL_STATE.id) {
        if (foundWebPage) {
          newState = {...newState, ...stateWithWebPage(foundWebPage)};
        }
      }
      if (foundWebPage.webPageBlocs !== state.webPageBlocs
        && state.webPageBlocs !== INITIAL_STATE.webPageBlocs) {
        newState = {...newState, webPageBlocs: foundWebPage.webPageBlocs}
      }
    }
    if (props.sending && !state.sending) { // Start sending
      return {sending: true};
    }
    if (!props.sending && state.sending) { // End sending
      if (state.pictureToUpload && state.id) { // picture To Upload after deal sent
        props.postWebPagePicture(props.token, state.id, state.pictureToUpload,
          (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(state.pictureToUpload.name + ' -> ' + percentCompleted
              + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
          });
        return {
          pictureToUpload: null,
          sending: false
        };
      }
    }

    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {
    let translations = [
      {lang: 'fr', title: formState.title_fr},
      {lang: 'en', title: formState.title_en}
    ];

    // Add translations to object
    formState = {...formState, translations};

    // Retrieve ImageToUpload if available
    if (formState.pictureToUpload) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload: formState.pictureToUpload
      });
    }

    this.props.putWebPage(
      this.props.token,
      this.state.id,
      formState
    )
      .then((webPageId => {
        if (this.state.pictureToUpload) {
          this.setState({id: webPageId});
        }
      }))
      .catch((error) => {
        this.props.handleError(error);
      });

    event.preventDefault();
  };

  handleAdd = () => {
    this.props.history.push(PAGE_BLOC_ADD.replace(':pageId', this.state.id));
  };

  handleEdit = element => {
    this.props.history.push(PAGE_BLOC_EDIT
      .replace(':pageId', this.state.id)
      .replace(':pageBlocId', element.id));
  };

  handleDelete = element => {
    this.props.deleteWebPageBloc(this.props.token, this.state.id, element)
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  handleUpOrder = element => {
    let uppedPageBlocIndex = this.state.webPageBlocs.indexOf(element);
    element.order--;
    let downedPageBloc = this.state.webPageBlocs[uppedPageBlocIndex - 1];
    downedPageBloc.order++;
    // TODO Problème here
    this.props.putWebPageBloc(
      this.props.token,
      this.state.id,
      element.id,
      element
    )
      .catch((error) => {
        this.props.handleError(error);
      })
      .then(() => {
        this.props.putWebPageBloc(
          this.props.token,
          this.state.id,
          downedPageBloc.id,
          downedPageBloc
        ).catch((error) => {
          this.props.handleError(error);
        });
      });
  };

  handleDownOrder = element => {
    let downedPageBlocIndex = this.state.webPageBlocs.indexOf(element);
    element.order++;
    let uppedPageBloc = this.state.webPageBlocs[downedPageBlocIndex + 1];
    uppedPageBloc.order--;
    this.props.putWebPageBloc(
      this.props.token,
      this.state.id,
      element.id,
      element
    ).catch((error) => {
        this.props.handleError(error);
      })
      .then(() => {
        this.props.putWebPageBloc(
          this.props.token,
          this.state.id,
          uppedPageBloc.id,
          uppedPageBloc
        ).catch((error) => {
            this.props.handleError(error);
          });
      });
  };

  render() {

    const {pending, sending} = this.props;

    const {id} = this.state;

    const columnData = [
      {
        id: 'order',
        label: 'Ordre',
        numeric: false,
        disablePadding: false},
      {
        id: 'translations[lang === \'fr\'].title',
        label: 'Titre',
        specialOrderBy: 'title',
        numeric: false,
        disablePadding: false
      },
      {
        id: 'type',
        label: 'Type',
        numeric: false,
        disablePadding: false
      }
    ];

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === '' ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    const formFields = [

      {id: 'block_fr_title', type:'title', label:'Traduction française', noPadding:true},
      {id: 'divider_fr', type:'divider', noPadding:true},
      {id: 'title_fr', type: 'textField', gridMD: 6, label: 'Titre FR', isMandatory: true},

      {id: 'block_en_title', type:'title', label:'Traduction anglaise', noPadding:true},
      {id: 'divider_en', type:'divider', noPadding:true},
      {id: 'title_en', type: 'textField', gridMD: 6, label: 'Titre EN', isMandatory: false},

      {id: 'block_image_title', type:'title', label:'Image', noPadding:true},
      {id: 'divider_image', type:'divider', noPadding:true},
      {id: 'imagePath', picture: 'pictureToUpload',
        type: 'pictureDropzone', gridMD: 6, isMandatory: false},
    ];

    const customForm = (
      <div>
        <CustomForm
          data={this.state}
          formFields={formFields}
          buttonLabel={buttonLabel}
          onSubmit={this.onSubmit}
          sending={sending}
        />
        <Grid container>
          <Typography align="left" variant="h6" gutterBottom>
            Blocs
          </Typography>
          <Grid item xs={12} sm={12} md={12}>
            {this.props.pending &&
            <Spinner />
            }
            {!this.props.pending && (
              <CustomTable
                datas={this.state.webPageBlocs}
                onAdd={this.handleAdd}
                onEdit={this.handleEdit}
                onDelete={this.handleDelete}
                buttonLabel='Ajouter un bloc'
                paginationLabel='bloc par page'
                emptyDataMessage='Aucun bloc'
                deleteDialogText='Êtes-vous sûr de vouloir supprimer ce bloc ?'
                columnData={columnData}
                sending={this.props.sending}
                showDelete={AuthService.isGranted(ROLE_ADMIN)}

                canReorder
                onUpOrder={this.handleUpOrder}
                onDownOrder={this.handleDownOrder}
              />
            )}
          </Grid>
        </Grid>
      </div>
    );

    if (pending) {
      return (load);
    } else
      return (customForm);
  }
}

function mapStateToProps(state) {
  return {
    token: state.admins.authAdmin.token,
    webPages: state.webPages.content,
    pending: state.webPages.pending,
    sending: state.webPages.sending
  };
}

export default compose(
  connect(mapStateToProps,
    {
      getWebPages, putWebPage, putWebPageBloc,
      postWebPagePicture, deleteWebPageBloc, handleError
    }),
)(WebPageForm);
