import axios from 'axios';
import * as api from "../constants/apiRoutes";

const ApiService = {

  /**
   * Génére le header pour les flux admin
   *
   * @param token
   * @returns Object
   */
  headers(token = null) {
    const header = {};
    if (token) {
      header.Authorization = `Bearer ${token}`;
    }
    return header;
  },

  /**
   * Login
   */

  login(data) {
    return axios
      .post(api.API_LOGIN, data)
      .then(response => response.data);
  },

  refresh(data) {
    return axios
      .post(api.API_REFRESH, data)
      .then(response => response.data);
  },

  /**
   * Admins
   */

  getAdmins(token) {
    return axios
      .get(api.API_ADMINS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postAdmin(token, data) {
    return axios
      .post(api.API_ADMINS, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putMe(token, data) {
    return axios
      .put(api.API_ADMINS_ME, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteMe(token) {
    return axios
      .delete(api.API_ADMINS_ME, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putAdmin(token, id, data) {
    return axios
      .put(api.API_ADMINS_ID.replace(':adminId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteAdmin(token, id) {
    return axios
      .delete(api.API_ADMINS_ID.replace(':adminId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },


  /**
   * Password
   */

  postLostPassword(data) {
    return axios
      .post(api.API_PASSWORD_LOST, data)
      .then(response => response.data);
  },

  postChangePassword(data) {
    return axios
      .post(api.API_PASSWORD_CHANGE, data)
      .then(response => response.data);
  },

  /**
   * Configuration
   */

  getConfigurations(token) {
    return axios
      .get(api.API_CONFIGURATIONS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putConfigurations(token, data) {
    return axios
      .put(api.API_CONFIGURATIONS, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Tips
   */

  getTips(token) {
    return axios
      .get(api.API_TIPS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postTip(token, data) {
    return axios
      .post(api.API_TIPS, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postTipPicture(token, id, picture, onUploadProgress = null) {
    // eslint-disable-next-line no-undef
    let data = new FormData();
    data.append('image', picture);

    return axios
      .post(api.API_TIPS_ID_IMAGE.replace(':tipId', id), data, {
        headers: this.headers(token),
        onUploadProgress: onUploadProgress
      })
      .then(response => {
        return response.data;
      });
  },

  putTip(token, id, data) {
    return axios
      .put(api.API_TIPS_ID.replace(':tipId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteTip(token, id) {
    return axios
      .delete(api.API_TIPS_ID.replace(':tipId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Themes
   */

  getThemes(token) {
    return axios
      .get(api.API_THEMES, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postTheme(token, data) {
    return axios
      .post(api.API_THEMES, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postThemeBloc(token, themeId, data) {
    return axios
      .post(api.API_THEMES_BLOC.replace(':themeId', themeId), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postThemeBlocPicture(token, themeId, themeBlocId, picture, onUploadProgress = null) {
    // eslint-disable-next-line no-undef
    let data = new FormData();
    data.append('image', picture);
    return axios
      .post(api.API_THEMES_BLOC_ID_IMAGE
        .replace(':themeId', themeId).replace(':themeBlocId', themeBlocId), data, {
        headers: this.headers(token),
        onUploadProgress: onUploadProgress
      })
      .then(response => {
        return response.data;
      });
  },

  putTheme(token, id, data) {
    return axios
      .put(api.API_THEMES_ID.replace(':themeId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putThemeBloc(token, themeId, themeBlocId, data) {
    return axios
      .put(api.API_THEMES_BLOC_ID
        .replace(':themeId', themeId).replace(':themeBlocId', themeBlocId), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteTheme(token, id) {
    return axios
      .delete(api.API_THEMES_ID.replace(':themeId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteThemeBloc(token, themeId, themeBlocId) {
    return axios
      .delete(api.API_THEMES_BLOC_ID
        .replace(':themeId', themeId).replace(':themeBlocId', themeBlocId), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Themes
   */

  getWebPages(token) {
    return axios
      .get(api.API_WEB_PAGES, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postWebPageBloc(token, webPageId, data) {
    return axios
      .post(api.API_WEB_PAGES_BLOC.replace(':pageId', webPageId), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postWebPagePicture(token, webPageId, picture, onUploadProgress = null) {
    // eslint-disable-next-line no-undef
    let data = new FormData();
    data.append('image', picture);
    return axios
      .post(api.API_WEB_PAGES_ID_IMAGE.replace(':pageId', webPageId), data, {
        headers: this.headers(token),
        onUploadProgress: onUploadProgress
      })
      .then(response => {
        return response.data;
      });
  },

  postWebPageBlocPicture(token, webPageId, webPageBlocId, lang, picture, onUploadProgress = null) {
    // eslint-disable-next-line no-undef
    let data = new FormData();
    data.append('image', picture);
    return axios
      .post(api.API_WEB_PAGES_BLOC_ID_IMAGE
        .replace(':pageId', webPageId).replace(':pageBlocId', webPageBlocId)
        .replace(':lang', lang), data, {
        headers: this.headers(token),
        onUploadProgress: onUploadProgress
      })
      .then(response => {
        return response.data;
      });
  },

  putWebPage(token, id, data) {
    return axios
      .put(api.API_WEB_PAGES_ID.replace(':pageId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putWebPageBloc(token, webPageId, webPageBlocId, data) {
    return axios
      .put(api.API_WEB_PAGES_BLOC_ID
        .replace(':pageId', webPageId).replace(':pageBlocId', webPageBlocId), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },


  deleteWebPageBloc(token, webPageId, webPageBlocId) {
    return axios
      .delete(api.API_WEB_PAGES_BLOC_ID
        .replace(':pageId', webPageId).replace(':pageBlocId', webPageBlocId), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },


  // Alert

  getAlertCategoriess(token) {
    return axios
      .get(api.API_ALERT_CATEGORIES, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  getAlertPresets(token) {
    return axios
      .get(api.API_ALERT_PRESETS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  getAlerts(token) {
    return axios
      .get(api.API_ALERTS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postAlert(token, data) {
    return axios
      .post(api.API_ALERTS, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putAlert(token, id, data) {
    return axios
      .put(api.API_ALERT.replace(':alertId', id), data,{
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteAlert(token, id) {
    return axios
      .delete(api.API_ALERT.replace(':alertId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },
};

export default ApiService;
