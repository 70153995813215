import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {compose} from "recompose";
import {Link, withRouter} from "react-router-dom";

import {withStyles} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Divider from "@material-ui/core/Divider/Divider";
import List from "@material-ui/core/List/List";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Hidden from "@material-ui/core/Hidden/Hidden";
import Drawer from "@material-ui/core/Drawer/Drawer";
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import BuildIcon from '@material-ui/icons/Build';
import LogoutIcon from '@material-ui/icons/CancelPresentation';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import WebIcon from '@material-ui/icons/Web';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import InfoIcon from '@material-ui/icons/Info';

import {TIG_RASPBERRY, TIG_WHITE} from "../constants/colors";
import {ADMINS, ALERT, PAGES, CONFIG, HOMEPAGE, SIGN_IN, THEME, TIPS} from "../constants/routes";
import {ROLE_ADMIN} from "../constants/roles";
import {logout} from "../stores/admins/actions";
import AuthService from '../services/AuthService';
import Spinner from "../components/common/Spinner";
import LargePaper from "../components/common/LargePaper";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerHeader: {
    ...theme.mixins.toolbar,
    backgroundColor: TIG_RASPBERRY,
    color: TIG_WHITE,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    paddingLeft: '24px'
  },
  drawerPaper: {
    width: drawerWidth,
    margin: 'auto'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    maxWidth: '100%'
  },
});

const drawer = (classes, handleLogout, location) => (
  <div>
    <div className={classes.drawerHeader}>
      <Typography variant="h6" color="inherit" noWrap>
        Tignes Back-office
      </Typography>
    </div>

    <Divider />

    {AuthService.isGranted(ROLE_ADMIN) && (
      <div>
        <List>
          <Link to={HOMEPAGE}>
            <ListItem
              button
              key='home'
              selected={location.pathname === HOMEPAGE}
            >
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary='Accueil' />
            </ListItem>
          </Link>
        </List>

        <Divider />
      </div>
    )}

    <List>
      <Link to={ALERT}>
        <ListItem
          button
          key='alert'
          selected={location.pathname === ALERT}
        >
          <ListItemIcon><NotificationImportantIcon /></ListItemIcon>
          <ListItemText primary='Alertes' />
        </ListItem>
      </Link>
      {AuthService.isGranted(ROLE_ADMIN) && (
        <div>
          <Link to={THEME}>
            <ListItem
              button
              key='theme'
              selected={location.pathname === THEME}
            >
              <ListItemIcon><ColorLensIcon /></ListItemIcon>
              <ListItemText primary='Thèmes' />
            </ListItem>
          </Link>
          <Link to={PAGES}>
            <ListItem
              button
              key='pages'
              selected={location.pathname === PAGES}
            >
              <ListItemIcon><WebIcon /></ListItemIcon>
              <ListItemText primary='Pages' />
            </ListItem>
          </Link>
          <Link to={TIPS}>
            <ListItem
              button
              key='tips'
              selected={location.pathname === TIPS}
            >
              <ListItemIcon><InfoIcon /></ListItemIcon>
              <ListItemText primary='Astuces' />
            </ListItem>
          </Link>
        </div>
      )}
    </List>

    {AuthService.isGranted(ROLE_ADMIN) && (
      <div>
        <Divider />
        <List>
          <Link to={CONFIG}>
            <ListItem
              button
              key='configurations'
              selected={location.pathname === CONFIG}
            >
              <ListItemIcon><BuildIcon /></ListItemIcon>
              <ListItemText primary='Configuration' />
            </ListItem>
          </Link>
          <Link to={ADMINS}>
            <ListItem
              button
              key='admins'
              selected={location.pathname.startsWith(ADMINS)}
            >
              <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
              <ListItemText primary='Administrateurs' />
            </ListItem>
          </Link>
        </List>
      </div>
    )}
    <Divider />

    <List>
      <ListItem button key='logout' onClick={handleLogout}>
        <ListItemIcon><LogoutIcon /></ListItemIcon>
        <ListItemText primary='Se déconnecter' />
      </ListItem>
    </List>
  </div>
);

const INITIAL_STATE = {
  mobileOpen: false,
};

const withMenu = title => (Component) => {
  class withMenu extends React.Component {

    static propTypes = {
      classes: PropTypes.object.isRequired,
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }).isRequired,
    };

    constructor(props) {
      super(props);
      this.state = {...INITIAL_STATE};
    }

    handleDrawerToggle = () => {
      this.setState(state => ({mobileOpen: !state.mobileOpen}));
    };

    handleLogout = () => {
      AuthService.logout();
      this.props.logout();
      this.props.history.push(SIGN_IN);
    };

    render() {
      return (
        <div className={this.props.classes.root}>
          <AppBar position="fixed" className={this.props.classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
                className={this.props.classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" noWrap>
                {title}
              </Typography>
            </Toolbar>
          </AppBar>
          <nav className={this.props.classes.drawer}>
            <Hidden lgUp implementation="css">
              <Drawer
                variant="temporary"
                anchor='left'
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: this.props.classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer(this.props.classes, this.handleLogout, this.props.location)}
              </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
              <Drawer
                classes={{
                  paper: this.props.classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer(this.props.classes, this.handleLogout, this.props.location)}
              </Drawer>
            </Hidden>
          </nav>
          <main className={this.props.classes.content}>
            <div className={this.props.classes.toolbar} />
            {this.props.adminPending && <Spinner />}
            {this.props.pending && (<LargePaper title="Chargement"><Spinner /></LargePaper>)}
            {!this.props.adminPending && <Component {...this.props} />}
          </main>
        </div>
      )
    }
  }

  function mapStateToProps(state) {
    return {
      adminPending: state.admins.refreshPending,
      pending: state.admins.pending
    };
  }

  return compose(
    withRouter,
    connect(mapStateToProps, {
      logout
    }),
    withStyles(styles)
  )(withMenu);
};

export default withMenu;
