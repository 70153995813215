export default class ArrayService {
  // ----------------
  // Computation

  static sort(a, b, property, order) {
    let prop1;
    let prop2;
    if ( property && property.includes(".") )
    {
      let properties = property.split(".");
      properties.map(property => {
        a = a[property];
        b = b[property];
      });
      prop1 = a;
      prop2 = b;
    } else {
      prop1 = (a[property] !== null && a[property] !== undefined) ? a[property] : -1;
      prop2 = (b[property] !== null && b[property] !== undefined) ? b[property] : -1;
    }
    if (typeof prop1 === 'string') {
      prop1 = prop1.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }
    if (typeof prop2 === 'string') {
      prop2 = prop2.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }

    if (order === 'asc') {
      return prop1 > prop2 ? -1 : 1;
    }
    return prop1 < prop2 ? -1 : 1;
  }
}
