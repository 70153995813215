import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {compose} from "recompose";
import withAuthorization from "../../hocs/withAuthorization";
import LargePaper from "../../components/common/LargePaper";
import AlertForm from "../../components/forms/AlertForm";
import Spinner from "../../components/common/Spinner";
import {NOT_FOUND} from "../../constants/routes";
import {getAlertPresets} from "../../stores/alertPresets/actions";
import {getAlertCategories} from "../../stores/alertCategories/actions";
import {getAlerts} from "../../stores/alerts/actions";

const INITIAL_STATE = {
  presetId: "",
  alert: {}
};

export class AlertAddPage extends React.Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.object.isRequired,
    pending: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  static getDerivedStateFromProps(props, state) {
    let alertId = props.match.params.alertId;
    let newState = null;
    if (!props.alertCategories && !props.pending) {
      props.getAlertCategories(props.authAdmin.token);
    }
    if (!props.alertPresets && !props.pending) {
      props.getAlertPresets(props.authAdmin.token);
    }
    if (!props.alerts && !props.pending) {
      props.getAlerts(props.authAdmin.token);
    }
    if (props.alerts && props.alertPresets && props.alertCategories) {
      if (state.alert === INITIAL_STATE.alert && state.presetId === INITIAL_STATE.presetId) {
        let isAlert = (element) => {
          return element.id === alertId;
        };
        let alert = props.alerts.find((element) => {return isAlert(element)});

        newState = {...newState, alert, presetId: alert.presetId};
      }
    }
    return newState;
  }

  render() {
    if (this.props.pending) {
      return (<Spinner />);
    } else if (this.state.presetId === undefined) {
      this.props.history.push(NOT_FOUND);
    } else {
      return (
        <LargePaper title="Éditer une alerte">
          <AlertForm
            history={this.props.history}
            presetId={this.state.presetId}
            alert={this.state.alert}
          />
        </LargePaper>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    authAdmin: state.admins.authAdmin,
    alertCategories: state.alertCategories.content,
    alertPresets: state.alertPresets.content,
    alerts: state.alerts.content,
    pending: state.alerts.pending || state.alertCategories.pending || state.alertPresets.pending,
    sending: state.alerts.sending,
    error: state.alerts.error || state.alertCategories.error || state.alertPresets.error,
  };
}

export default compose(
  connect(
    mapStateToProps,
    {getAlertPresets, getAlertCategories, getAlerts}
  ),
  withAuthorization('Alertes')
)(AlertAddPage);