import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import {withStyles} from "@material-ui/core";
import 'react-quill/dist/quill.snow.css';

import {
  getWebPages,
  postWebPageBloc,
  postWebPageBlocPicture,
  putWebPageBloc
} from "../../stores/webPages/actions";
import {handleError} from "../../stores/infos/actions";
import {PAGE_EDIT} from "../../constants/routes";
import Spinner from "../common/Spinner";

import CustomSelectField from "../common/form/CustomSelectField";
import CustomForm, {styles} from "../common/form/CustomForm";
import stylesDefault from "../../constants/stylesDefault";

// Types
const TYPES = {
  richtext: "Texte",
  image: "Image",
  imagelink: "Lien",
  divider: "Séparation",
  title: "Titre",
};

// Intial State of the Component
const INITIAL_STATE = {
  id: '',
  order: '',
  type: null,
  title_fr: '',
  content_fr: '',
  imagePath_fr: '',
  pictureToUpload_fr: null,
  title_en: '',
  content_en: '',
  pictureToUpload_en: null,
  sending: false
};

const stateWithWebPageBloc = webPageBloc => ({
  id: webPageBloc.id,
  order: webPageBloc.order,
  type: webPageBloc.type,
  title_fr: webPageBloc.translations.find(translation => translation.lang === 'fr').title,
  content_fr: webPageBloc.translations.find(translation => translation.lang === 'fr').content,
  title_en: webPageBloc.translations.find(translation => translation.lang === 'en').title,
  content_en: webPageBloc.translations.find(translation => translation.lang === 'en').content
});

export class WebPageBlocForm extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        pageId: PropTypes.string,
      })
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    postWebPageBloc: PropTypes.func.isRequired,
    putWebPageBloc: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.webPages && !props.pending && props.token
      && props.match && props.match.params.pageId) {
      props.getWebPages(props.token);
    }
    if (props.webPages && props.match && props.match.params.pageId
      && state.id === INITIAL_STATE.id) {

      const webPages = props.webPages;
      const currentWebPageId = props.match.params.pageId;
      const foundWebPage = webPages.find(theme => theme.id === currentWebPageId);

      newState = {...newState, webPageBlocs: foundWebPage.webPageBlocs};

      if (props.match.params.pageBlocId) {
        const foundWebPageBloc = foundWebPage.webPageBlocs
          .find(themeBloc => themeBloc.id === props.match.params.pageBlocId);
        console.log(foundWebPageBloc);
        if (foundWebPageBloc) {
          newState = {...newState, ...stateWithWebPageBloc(foundWebPageBloc)};
        }
      }
    }

    if (props.sending && !state.sending) { // Start sending
      return {sending: true};
    }
    if (!props.sending && state.sending) { // End sending
      if (state.pictureToUpload_fr && state.id) { // picture To Upload after deal sent
        props.postWebPageBlocPicture(
          props.token, props.match.params.pageId, state.id, 'fr', state.pictureToUpload_fr,
          (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(state.pictureToUpload_fr.name + ' -> ' + percentCompleted
              + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
          });
        return {
          pictureToUpload_fr: null,
          sending: state.pictureToUpload_en !== null
        };
      } else if (state.pictureToUpload_en && state.id) { // picture To Upload after deal sent
        props.postWebPageBlocPicture(
          props.token, props.match.params.pageId, state.id, 'en', state.pictureToUpload_en,
          (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(state.pictureToUpload_en.name + ' -> ' + percentCompleted
              + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
          });
        return {
          pictureToUpload_en: null,
          sending: null
        };
      } else if (!state.pictureToUpload_fr && !state.pictureToUpload_en) {
        props.history.push(PAGE_EDIT.replace(':pageId', state.pageId));
      }
    }

    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      pageId: props.match.params.pageId
    };
  }

  onSubmit = (event, formState) => {
    let translations = [
      {lang: 'fr', title: formState.title_fr, content: formState.content_fr},
      {lang: 'en', title: formState.title_en, content: formState.content_en}
    ];

    // Add translations to object
    formState = {...formState, type: this.state.type, translations};

    // Retrieve ImageToUpload if available
    if (formState.pictureToUpload_fr) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload_fr: formState.pictureToUpload_fr
      });
    }
    if (formState.pictureToUpload_en) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload_en: formState.pictureToUpload_en
      });
    }

    if (this.state.id === INITIAL_STATE.id) {
      // Set order when posting
      formState = {...formState, order: this.state.webPageBlocs.length};
      this.props.postWebPageBloc(
        this.props.token,
        this.state.pageId,
        formState
      )
        .then((pageBlocId => {
          if (this.state.pictureToUpload_fr || this.state.pictureToUpload_en) {
            this.setState({id: pageBlocId});
          }
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    } else {
      this.props.putWebPageBloc(
        this.props.token,
        this.state.pageId,
        this.state.id,
        formState
      )
        .then((pageBlocId => {
          if (this.state.pictureToUpload_fr || this.state.pictureToUpload_en) {
            this.setState({id: pageBlocId});
          }
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    }

    event.preventDefault();
  };

  render() {

    const {pending, sending, classes} = this.props;

    const {type, id} = this.state;

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === '' ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    const FrTitle = {
      id: 'block_fr_title',
      type: 'title',
      label: 'Traduction française',
      noPadding: true
    };

    const FrDivider = {id: 'divider_fr', type: 'divider', noPadding: true};

    const EnTitle = {
      id: 'block_en_title',
      type: 'title',
      label: 'Traduction anglaise',
      noPadding: true
    };

    const EnDivider = {id: 'divider_fr', type: 'divider', noPadding: true};

    const formFieldsRichText = [
      FrTitle, FrDivider,
      {id: 'title_fr', type: 'textField', gridMD: 6, label: 'Titre', isMandatory: true},
      {id: 'content_fr', type: 'richText', gridMD: 12, label: 'Contenu', isMandatory: true},
      EnTitle, EnDivider,
      {id: 'title_en', type: 'textField', gridMD: 6, label: 'Titre', isMandatory: false},
      {id: 'content_en', type: 'richText', gridMD: 12, label: 'Contenu', isMandatory: false}
    ];

    const formFieldsImage = [
      FrTitle, FrDivider,
      {id: 'title_fr', type: 'textField', gridMD: 6, label: 'Titre', isMandatory: true},
      {
        id: 'content_fr', picture: 'pictureToUpload_fr',
        type: 'pictureDropzone', gridMD: 12, label: 'Contenu', isMandatory: true
      },
      EnTitle, EnDivider,
      {id: 'title_en', type: 'textField', gridMD: 6, label: 'Titre', isMandatory: false},
      {
        id: 'content_en', picture: 'pictureToUpload_en',
        type: 'pictureDropzone', gridMD: 12, label: 'Contenu', isMandatory: false
      }
    ];

    const formFieldsTitle = [
      FrTitle, FrDivider,
      {id: 'title_fr', type: 'textField', gridMD: 6, label: 'Titre', isMandatory: true},
      EnTitle, EnDivider,
      {id: 'title_en', type: 'textField', gridMD: 6, label: 'Titre', isMandatory: false}
    ];

    const formFieldsDivider = [
      FrTitle, FrDivider,
      {id: 'title_fr', type: 'textField', gridMD: 6, label: 'Titre', isMandatory: false},
      EnTitle, EnDivider,
      {id: 'title_en', type: 'textField', gridMD: 6, label: 'Titre', isMandatory: false}
    ];


    let allFormField;
    switch(type) {
      case "richtext":
        allFormField = formFieldsRichText;
        break;
      case "title":
        allFormField = formFieldsTitle;
        break;
      case "divider":
        allFormField = formFieldsDivider;
        break;
      default:
        allFormField = formFieldsImage;
        break;
    }

    const customForm = (
      <div>

        <Grid container>

          <Grid item xs={false} lg={2} />
          <Grid item xs={12} lg={8}>
            <form>
              <Grid container style={stylesDefault.fromGridContainer} spacing={16}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.noPadding}
                  key="title_select_grid"
                >
                  <Typography
                    variant='h6'
                    id="title_select"
                    className={classes.title}
                  >
                    Choisissez un type de bloc
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.noPadding}
                  key="divider_select_grid"
                >
                  <Divider id="divider_select" variant="middle" className={classes.divider} />
                </Grid>
                <CustomSelectField
                  gridMD={6}
                  id="type"
                  label='Type'
                  onChange={
                    event => {
                      this.setState({type: event.target.value})
                    }
                  }
                  value={type}
                  isMandatory
                >
                  {
                    Object.keys(TYPES).map(
                      function (value) {
                        return (
                          <MenuItem key={value} value={value}>
                            {TYPES[value]}
                          </MenuItem>
                        )
                      }
                    )
                  }
                </CustomSelectField>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <CustomForm
          data={this.state}
          formFields={allFormField}
          buttonLabel={buttonLabel}
          onSubmit={this.onSubmit}
          sending={sending}
        />
      </div>
    );

    if (pending) {
      return (load);
    } else {
      return (customForm);
    }
  }
}

function mapStateToProps(state) {
  return {
    token: state.admins.authAdmin.token,
    webPages: state.webPages.content,
    pending: state.webPages.pending,
    sending: state.webPages.sending
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps,
    {getWebPages, postWebPageBloc, postWebPageBlocPicture, putWebPageBloc, handleError}),
)(WebPageBlocForm);
