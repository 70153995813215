import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ALERT_PRESETS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.ALERT_PRESETS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        content: action.payload,
      };
    }
    case types.ALERT_PRESETS_FAILED: {
      return {
        ...state,
        error: action.payload,
        pending: false
      };
    }
    default:
      break;
  }
  return state;
}
