import React from 'react';
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import queryString from 'query-string';
import {withStyles} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Typography from "@material-ui/core/Typography/Typography";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Icon from "@material-ui/core/Icon/Icon";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import IphoneTabbar from "../../components/iphone/IphoneTabbar";
import appStyles from "../../constants/stylesIphone";

export const IphoneAlertPage = (props) => {
  let {classes} = props;
  let queries = queryString.parse(props.location.search);
  let title = (queries.title) ? queries.title : 'title';
  let content = (queries.content) ? queries.content : 'content';
  let date = new Date();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Icon color="inherit" aria-label="Menu">
            <ArrowBackIos />
          </Icon>
        </Toolbar>
      </AppBar>
      <div className={classes.body}>
        <div className={classes.topPadding}>
          <Typography variant="h5" className={classes.hour}>
            {title}
          </Typography>
        </div>
        <div className={classes.center}>
          <Typography variant="subtitle1" className={classes.hour}>
            {date.toLocaleString('fr-FR', {timeZone: 'UTC'})}
          </Typography>
        </div>
        <div className={classes.topPadding}>
          <Typography variant="subtitle1">
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{__html: content }} />
          </Typography>
        </div>
      </div>
      <IphoneTabbar />
    </div>
  );
};

IphoneAlertPage.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};
export default compose(withRouter, withStyles(appStyles))(IphoneAlertPage);